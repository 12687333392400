import * as React from "react";
import {
    LinearProgress,
    Tooltip,
} from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

const ProgressWithDynamicTooltip = (props) => {
    const data = props?.data;
    const isIncrease = data.estimatedDays <= data.actual
    const linearValue = isIncrease ? ((data.estimatedDays / data.actual) * 100) : (data.actual / data.estimatedDays) * 100
    const actualValuePostion = isIncrease ? ((data.estimatedDays / data.actual) * 100) : (data.actual / data.estimatedDays) * 100
    const colorBox = {
        position: 'absolute',
        left: `${isIncrease ? 100 : actualValuePostion}% `,
        transform: 'translateX(-50%)',
        top: 6,

    };
    const emptyBox = {
        position: 'absolute',
        left: `${isIncrease ? actualValuePostion : 100}% `,
        transform: 'translateX(-50%)',
        top: 10
    }

    return (
        <Box position="relative" width="100%">
            <LinearProgress variant="determinate" value={linearValue} sx={{
                height: props?.height || 10,
               
                backgroundColor: isIncrease ? '#F57C7C' : 'lightgray',
                '& .MuiLinearProgress-bar': {
                    backgroundColor: isIncrease ? 'lightgray' : '#62E89C'
                }
            }} />
            <Tooltip componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: data.estimatedDays === 0 ? "lightgray" : isIncrease ? '#F57C7C' : '#62E89C',
                        borderRadius: 0,
                        minWidth: 30,
                        height : 20,
                        textAlign: 'center',
                        '& .MuiTooltip-arrow': {
                            color: data.estimatedDays === 0 ? "lightgray" : isIncrease ? '#F57C7C' : '#62E89C'
                        },
                    },
                },
            }}
            PopperProps={{
                style: { zIndex: 1 }, // Adjust the zIndex value as needed
            }}
        
            title={data.actual} open={true} placement="top" arrow style={colorBox} >
            </Tooltip>
            <Tooltip componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: '#ebebeb',
                        color: '#656565',
                        minWidth: 30,
                        fontFamily: 'Poppins-Medium',
                        textAlign: 'center',
                        borderRadius: 0,
                        '& .MuiTooltip-arrow': {
                            color: '#ebebeb',
                        },
                    },
                },
            }}
            PopperProps={{
                style: { zIndex: 1 }, // Adjust the zIndex value as needed
            }}
            title={data.estimatedDays} open={true} placement="top" arrow style={emptyBox} >
            
            </Tooltip >
            <Box style={{
                height: props?.height + 10 || 20,
                width: props?.height ? 3 : 2,
                borderRadius: 5,
                background: '#656565',
                position: 'absolute',
                left: `${actualValuePostion}% `,
                top: -5,
            }}>

            </Box>

        </Box >
    );
};
export { ProgressWithDynamicTooltip }