import { Box, IconButton, Paper, Tooltip } from "@mui/material"
import React, { useState } from "react"
import store from "../../../../../../redux/store/store"
import _ from "lodash"
import Helper from '../../../../../../assets/images/helper.svg';

const data = [
    {
        name: 'HIV',
        desc: 'Average positive cases per month',
        value: 0.7,
        _id: '1'
    },
    {
        name: 'Alcohol',
        desc: 'Average drink per week',
        value: 1.2,
        _id: '2'
    },
    {
        name: 'Smoking',
        desc: 'Average cigarettes per day',
        value: 0.2,
        _id: '3'
    },
    {
        name: 'Exercise',
        desc: 'Average hours per week',
        value: 0.9,
        _id: '4'
    },
    {
        name: 'Meat',
        desc: 'Average portions per day',
        value: 0.2,
        _id: '5'
    },
    {
        name: 'First Born Age',
        desc: 'Average age',
        value: 9.2,
        _id: '6'
    },
    {
        name: 'No of Children',
        desc: 'Average no of children',
        value: 2.3,
        _id: '7'
    },
    {
        name: 'Age of 1st Sexual Intercourse',
        desc: 'Average Age',
        value: 18.3,
        _id: '8'
    }
]


const RiskFactors = () => {
    let { notificationConfig } = store?.getState()?.nlaReducer;
    const [riskFactors, setRiskFactors] = useState([])
    React.useEffect(() => {
        let { riskFactors } = store?.getState()?.nlaReducer;
        setRiskFactors((riskFactors || []).map((item, i) => {
            return ({
                ...item,
                name: item?.title,
                desc: item?.description,
                value: parseFloat(item?.value).toFixed(1) || 0,
                _id: i,
                patients: item?.patients
            })
        }))
    }, [store?.getState()?.nlaReducer?.riskFactors])


    return (

        <Paper
            elevation={0}
            sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1 }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "24px"
                }}
            >
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: "0px 0px 15px 0px"}}>
                    <span style={{fontSize: 16, fontFamily: "Poppins-SemiBold",
                                color: "#00133A",}}>Risk Factor</span>
                    <Box style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: 'space-between',
                        gap: 10,
                        alignItems: 'center'
                    }}>
                        <span
                            style={{
                                fontSize: 16,
                                fontFamily: "Poppins-SemiBold",
                                color: "#00133A",
                                // width: 40
                            }}>
                            Average
                        </span>
                        <span
                            style={{
                                fontSize: 16,
                                fontFamily: "Poppins-SemiBold",
                                color: "#00133A",
                                width: 60,
                                textAlign: 'right'
                            }}>
                            Patients
                        </span>
                    </Box>
                </div>
                {(riskFactors || []).map((item) => {
                    return (
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: 'space-between',
                                padding: "0px 0px 15px 0px",
                            }}
                            key={item._id}
                        >
                            <Box>
                                <Box style={{display: 'flex', alignItems: 'center'}}>
                                    <h3
                                        style={{
                                            fontSize: 14,
                                            fontFamily: "Poppins-SemiBold",
                                            color: "#00133A",
                                            margin: 0, padding: 0
                                        }}>
                                        {item.name}
                                    </h3>
                                    {item?.is_notify ? <Tooltip title={notificationConfig[item?.notify_key]} arrow placement="right">
                                        <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                                    </Tooltip>:null}
                                </Box>
                                <span
                                        style={{
                                            fontSize: 13,
                                            fontFamily: "Poppins-Regular",
                                            color: "#838383",
                                        }}>
                                        {item.desc}
                                    </span>
                                
                            </Box>
                            <Box style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: 'space-between',
                                gap: 10
                            }}>
                                <span
                                    style={{
                                        fontSize: 16,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                        width: 55
                                    }}>
                                    {item.value}
                                </span>
                                <span
                                    style={{
                                        fontSize: 16,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                        width: 40,
                                        textAlign: 'right'
                                    }}>
                                    {item.patients}
                                </span>
                            </Box>
                        </Box>
                    )
                })}
                 {
                _.isEmpty(riskFactors) && <span>No Data</span>
                }
            </Box>
        </Paper>
    )
}
export default RiskFactors