import { Box, Divider, InputLabel, Paper } from "@mui/material"
import RiskFactors from "./riskfactors"
import Referral from "./referrals"
import { BiSolidUpArrow } from "react-icons/bi"
import { BiSolidDownArrow } from "react-icons/bi"
import React, { useEffect, useState } from "react"
import store from "../../../../../redux/store/store"


const Deaths = () => {

    const [deaths, setDeaths] = useState(undefined)
    useEffect(() => {
        let { death } = store?.getState()?.nlaReducer;
        setDeaths({
            totalDeaths: {
                value: death?.total_death,
                percentage: Math.abs(death?.death_diff),
                isUp: death?.total_death > death?.total_death_previous
            },
        })
    }, [store?.getState()?.nlaReducer?.death])

    // const deaths = props?.deaths || {
    //     totalDeaths: {
    //         value: 36,
    //         percentage: 12,
    //         isUp: true
    //     },
    // }

    return (
        <Paper
            elevation={0}
            sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1, padding: '20px', height: 122 }}
        >
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                {/*Total Deaths*/}
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 0",
                    }}>
                    <InputLabel
                        style={{
                            fontSize: 13,
                            fontFamily: "Poppins-Medium",
                            color: "#237BBC",
                        }}
                    >
                        {"Total Deaths"}
                    </InputLabel>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            paddingTop: "10px",
                        }}
                    >
                        <h3
                            style={{
                                fontSize: 25,
                                fontFamily: "Poppins-SemiBold",
                                color: "#00133A",
                                margin: 0,
                                paddingRight: 10
                            }}
                        >
                            {deaths?.totalDeaths?.value}
                        </h3>
                        <span
                            style={{
                                fontSize: 14,
                                paddingRight : 5,
                                fontFamily: "Poppins-Medium",
                                color: "#6d706f",
                            }}
                        >
                            {deaths?.totalDeaths?.percentage}
                        </span>
                        {
                            deaths?.totalDeaths?.isUp ?
                            <BiSolidUpArrow color={"#6d706f"} size={8} />
                            :
                            <BiSolidDownArrow color={"#6d706f"} size={8} />
                        }
                       
                        
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}
export default Deaths