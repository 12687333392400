import * as React from "react";
import { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { PieChart as MuiPieChart, pieArcLabelClasses } from '@mui/x-charts'
import { styled } from '@mui/material/styles';
import {
  Grid,
  InputLabel,
  Paper,
  ThemeProvider,
  Typography,
  createTheme,
  LinearProgress,
  Tooltip,
  IconButton
} from "@mui/material";
import { useState } from "react";
import { Box, Link } from "@mui/material";
import { FiMonitor } from "react-icons/fi";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { BsFillSquareFill } from "react-icons/bs";
import Divider from '@mui/material/Divider';
import { Outlet, useNavigate } from "react-router-dom";
import { ProgressBar } from 'react-bootstrap';
import "../../../../../assets/css/patientJourney.css";
import { renderPieData } from "../../../../../constants/constant";
import Helper from '../../../../../assets/images/helper.svg';
import store from "../../../../../redux/store/store";

const RADIAN = Math.PI / 180;
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={'#7A7A7A'} style={{ fontSize: 12, fontFamily: 'Poppins-SemiBold' }}>
        {100}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text x={x} y={y} fill="#7A7A7A" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: 10, fontFamily: ' Poppins-Regular' }}>
      {value}
    </text>
  );
};



const CompletionRate = (props) => {
  console.log(props, "propspropspropspropsprops")
  let { notificationConfig } = store?.getState()?.nlaReducer;
  const data12 = [
    { name: 'a', studentss: parseInt(props?.sections.completion.value) },
  ];
  const value = data12[0].studentss;
  const totalValue = 100
  const startAngle = 0;
  const endAngle = (value / totalValue) * 360;
  return (
    <>
      <Paper
        elevation={0}
        sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: "10px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 20px"
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              // alignItems: "center",
              justifyContent: 'space-between',
              padding: "20px 0",
            }}
          >
            <Box
              style={{ display: "flex", flexDirection: "column", alignItems: '' }}
            >
              <Box style={{ width: 160 }}>

                <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                  <PieChart width={50} height={50}>
                    <Pie
                      data={data12}
                      dataKey="studentss"
                      startAngle={startAngle}
                      endAngle={endAngle}
                      outerRadius={25}
                      fill="#62E89C"
                    />
                  </PieChart>
                  <span
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins-Medium",
                      color: "#222222",
                      paddingLeft: 8,
                      width: 147
                    }}
                  >
                    {props?.sections.completion.value + "%"}
                    <Tooltip title={notificationConfig?.COMPLETION_SUCCESS_RATE} arrow placement="right">
                        <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                    </Tooltip>
                  </span>
                </Box>
              </Box>
            </Box>

          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', width: '100%', marginBottom: "3px" }}>
            <InputLabel
              style={{
                fontSize: 13,
                fontFamily: "Poppins-Regular",
                color: "#8E8E8E",
              }}
            >
              {"Process"}
            </InputLabel>
            <InputLabel
              style={{
                fontSize: 13,
                fontFamily: "Poppins-Regular",
                color: "#8E8E8E",
              }}
            >
              {"Completion Rate"}
              <Tooltip title={notificationConfig?.COMPLETION_APPOINTMENT_SUCCESS_RATE} arrow placement="right">
                  <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
              </Tooltip>
            </InputLabel>
          </Box>

          <Box
            style={{
              display: "flex", flexDirection: "column", alignItems: 'start', height: props?.export ? 700 : 150,
              overflowY: 'auto',
            }}
          >

            {props?.data?.map((processItem, i) => {
              return (
                <Box sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: 'space-between', width: '100%', marginBottom: '3px', paddingRight: '5px' }} key={i}>
                  <span
                    style={{
                      fontSize: 14,
                      fontFamily: "Poppins-SemiBold",
                      color: "#00133A",
                      // width: 30,
                      textAlign: 'right',
                      width: 'fit-content'
                    }}
                  >
                    {processItem.completion_rate + "%"}
                    {processItem.completion_rate_flag === "true" && <BiSolidUpArrow color={"#6d706f"} size={8} style={{ marginLeft: '3px' }} />}
                    {processItem.completion_rate_flag === "false" && <BiSolidDownArrow color={"#6d706f"} size={8} style={{ marginLeft: '3px' }} />}
                  </span>
                  {/* <span
                    style={{
                      fontSize: 14,
                      fontFamily: "Poppins-Medium",
                      color: "#838383",
                      width: 'calc(100% - 50px)'
                    }}
                  >
                    <BsFillSquareFill color={processItem.step_color} size={9} style={{ marginRight: 6 }} />
                    {processItem.appTitle}
                  </span> */}
                  <div
                    style={{
                      fontSize: 14,
                      fontFamily: "Poppins-Medium",
                      color: "#838383",
                      display: 'flex',
                      gap: 2,
                      width: 'calc(100% - 60px)'
                    }}
                  >
                    <BsFillSquareFill color={processItem.step_color} style={{ marginRight: 6, width: 10, marginTop: 4 }} />
                    <span style={{ width: 'fit-content' }}>{processItem.appTitle}</span>
                  </div>
                </Box>
              )

            })}


          </Box>

          <Divider variant="" />

          <Box  
            style={{
              display: "flex",
              flexDirection: "row",
              //alignItems: "center",
              justifyContent: "space-between",
              padding: "20px 0",
            }}
          >
            <Box className=" align-items-center" style={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <InputLabel
                style={{
                  fontSize: 13,
                  fontFamily: "Poppins-Semibold",
                  color: "#505050",
                }}
              >
                {"Days Delayed"}
                <Tooltip title={notificationConfig?.DAYS_DELAYED} arrow placement="right">
                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                </Tooltip>
              </InputLabel>

              <div style={{ marginTop: 20, marginBottom: 20 }}>
                {props?.delay_no_data ?
                  <div style={{ height: 100, marginLeft: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', }}><div style={{ width: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: '1px solid #b9b1b1', background: '#f44f4f', fontSize: 12, color: '#fff' }}>No Data</div></div> :
                  <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                    <Box style={{ display: "flex", alignItems: 'center', width: 100, height: 100 }}>
                      <MuiPieChart
                        series={[
                          {
                            innerRadius: 0,
                            data: props?.delay.map(item => {
                              return { ...item, value: parseInt(item?.patient_count) }
                            }),
                            arcLabel: (item) => `${item.value ? item.value : ''}`,
                            // arcLabelMinAngle: 45,
                            // cx:"100%",
                            // cy:"100%"

                          }]}
                        sx={{
                          [`& .${pieArcLabelClasses.root}`]: {
                            fill: 'white',
                            fontWeight: 'bold',
                            fontSize: 10,
                            width: '30px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          },
                        }}
                        width={100}
                        height={100}
                        margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
                        slotProps={{
                          legend: { hidden: true },
                        }}
                        colors={props?.delay.map(item => item?.step_color)}
                      />
                    </Box>
                  </div>
                }
              </div>
              {/* <Typography
                style={{
                  fontSize: 13,
                  fontFamily: "Poppins-Regular",
                  color: "#8E8E8E",
                  width: 150,
                  textAlign: "center"
                }}
              >
                Average Gap* between milestones
              </Typography>
              <Typography
                style={{
                  fontSize: 22,
                  fontFamily: "Poppins-Semibold",
                  color: "#2F2F2F",
                  marginTop: "12px"
                }}
              >
                {props?.sections.delayed.value}
              </Typography>
              <Typography
                style={{
                  fontSize: 12,
                  fontFamily: "Poppins-Regular",
                  color: "#6B6B6B",
                }}
              >
                *Accepted 5 Days
              </Typography> */}

            </Box>

            <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <InputLabel
                style={{
                  fontSize: 13,
                  fontFamily: "Poppins-Semibold",
                  color: "#505050",
                }}
              >
                {"Cancelled Appointment"}
                <Tooltip title={notificationConfig?.CANCELLED_APPOINTMENT} arrow placement="right">
                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                </Tooltip>
              </InputLabel>

              <div style={{ marginTop: 20, marginBottom: 20 }}>
                {props?.cancelled_no_data ?
                  <div style={{ height: 100, marginLeft: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', }}><div style={{ width: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: '1px solid #b9b1b1', background: '#f44f4f', fontSize: 12, color: '#fff' }}>No Data</div></div> :
                  <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                    <Box style={{ display: "flex", alignItems: 'center', width: 100, height: 100 }}>
                      <MuiPieChart
                        series={[
                          {
                            innerRadius: 0,
                            // outerRadius: 20,
                            data: renderPieData(props?.cancelled_appointments)?.[0],
                            arcLabel: (item) => `${item.value ? item.value : ''}`,
                            // arcLabelMinAngle: 45,

                          },
                          {
                            innerRadius: 35,
                            // outerRadius: 40,
                            data: renderPieData(props?.cancelled_appointments)?.[1],
                            arcLabel: (item) => `${item.value ? item.value : ''}`
                            // arcLabel: (item) => `${item.value}`,
                            // arcLabelMinAngle: 45,
                          },
                        ]}
                        sx={{
                          [`& .${pieArcLabelClasses.root}`]: {
                            fill: 'white',
                            fontWeight: 'bold',
                            fontSize: 10,
                            width: '30px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          },
                        }}
                        width={100}
                        height={100}
                        slotProps={{
                          legend: { hidden: true },
                        }}
                        colors={props?.cancelled_appointments.map(item => item.step_color)}
                        margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
                      />
                    </Box>
                  </div>
                }
              </div>


              {/* <Typography
                style={{
                  fontSize: 13,
                  fontFamily: "Poppins-Regular",
                  color: "#8E8E8E",
                  padding: "10px 0px"
                }}
              >
                Discontinued Patients
              </Typography>
              <Typography
                style={{
                  fontSize: 22,
                  fontFamily: "Poppins-Semibold",
                  color: "#2F2F2F",
                  marginTop: "12px"
                }}
              >
                {props?.sections.cancelled.value}
              </Typography> */}

            </Box>


          </Box>


        </Box>
        <Box>
            <div style={{textAlign: 'center', fontSize: 14, fontWeight: '600', marginBottom: 10}}>*Old data prior to change of 'Appointment titles'</div>
        </Box>
      </Paper>
    </>
  )
}
export default CompletionRate