import * as React from "react";

import {
  Grid,
  InputLabel,
  Paper,
  ThemeProvider,
  Typography,
  createTheme,
  LinearProgress,
  Tooltip
} from "@mui/material";
import { Box, Link } from "@mui/material";
import Divider from '@mui/material/Divider';
import "../../../../../assets/css/patientJourney.css";
import BarrierDetail from "./barrier";
import { ProgressBar } from 'react-bootstrap';
import store from "../../../../../redux/store/store";
import { useState } from "react";


const Barriers = () => {
  const [barriers, setBarriers] = useState([])
  React.useEffect(() => {
    let { barriesFigure } = store?.getState()?.nlaReducer;
    setBarriers([{
      value: barriesFigure?.predefined || 0,
      name: 'PREDEFINED',
      _id: '1',
    },
    {
      value: barriesFigure?.['non-defined'] || 0,//RV rename should be NON-DEFINED
      name: 'NON-DEFINED',
      _id: '2',
    },
    {
      value: barriesFigure?.resolved || 0,
      name: 'RESOLVED',
      _id: '4',
    },
    {
      value: barriesFigure?.active || 0,  //RV rename should be ACTIVE
      name: 'ACTIVE',
      _id: '5',
    },
    {
      value: barriesFigure?.others || 0,  //RV rename should be ACTIVE
      name: 'OTHERS',
      _id: '6',
    },
    ])
  }, [store?.getState()?.nlaReducer?.barriesFigure])

  const [barriersData, setBarriersData] = useState([])

  const getResolvedPercentage = (categories) => {
    return (parseInt(categories?.resolved_barrier_count) / (parseInt(categories?.resolved_barrier_count) + parseInt(categories?.others_barrier_count))) * 100
  }

  const getOthersPercentage = (categories) => {
    return (parseInt(categories?.others_barrier_count) / (parseInt(categories?.resolved_barrier_count) + parseInt(categories?.others_barrier_count))) * 100
  }

  React.useEffect(() => {
    let { barriers } = store?.getState()?.nlaReducer;
    setBarriersData((barriers || []).map(barrier => {
      return ({
        barrier_category: (barrier?.barrier_category || []).map(categories => {
          return ({
            resolved: categories?.resolved_barrier_count || 'NA',
            occurence: categories?.others_barrier_count || 'NA',
            name: categories?.category_name || 'NA',
            _id: categories?.category_id || 'NA',
            bartop: getResolvedPercentage(categories),  //RV
            barbottom: getOthersPercentage(categories), //RV
            expense: categories?.todo_expense || '0',
          })
        })
      })
    })
    )
  }, [store?.getState()?.nlaReducer?.barriers])

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      // alignContent: 'center',
      marginTop: '30px',
      flexWrap: "wrap",
    }}>
      <InputLabel
        style={{
          fontSize: 24,
          fontFamily: "Poppins-Medium",
          color: "#554886",
          marginLeft: '8px'
        }}
      >
        Barriers
      </InputLabel>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",

          "& > :not(style)": {
            m: 1,
            minWidth: 360,
            height: 122,
          },
        }}
      >
        <Paper
          elevation={0}
          sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1 }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 40px"
            }}
          >

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                //alignItems: "center",
                justifyContent: "space-between",
                padding: "20px 0",
                gap: 30
              }}
            >

                

              {(barriers || []).slice(0, 3).map((item) => {
                return (
                  <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                    <InputLabel
                      style={{
                        fontSize: 13,
                        fontFamily: "Poppins-Medium",
                        color: "#237BBC",
                      }}
                    >
                      {item?.name}
                    </InputLabel>
                    <Typography
                      style={{
                        fontSize: 22,
                        fontFamily: "Poppins-Semibold",
                        color: "#242424",
                      }}
                    >
                      {item?.value}
                    </Typography>
                  </Box>
                )
              })}

              <Box className="align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column", }}>
                <Divider
                  style={{
                    height: 1,
                    width: 40,
                    transform: "rotate(90deg)",
                    background: "lightgray",
                  }}
                />
              </Box>

              {(barriers || []).slice(3, 5).map((item) => {
                return (
                  <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                    <InputLabel
                      style={{
                        fontSize: 13,
                        fontFamily: "Poppins-Medium",
                        color: "#237BBC",
                      }}
                    >
                      {item?.name}
                    </InputLabel>
                    <Typography
                      style={{
                        fontSize: 22,
                        fontFamily: "Poppins-Semibold",
                        color: "#242424",
                      }}
                    >
                      {item?.value}
                    </Typography>
                  </Box>
                )
              })}


            </Box>


          </Box>
        </Paper>
      </Box >

      <Box
        className="section-container"
        sx={{
          "& > :not(style)": {
            m: 1,
            minWidth: 360,
            // height: 500,
          },
        }}
      >
        {(barriersData || []).map((barriers) => {
          return (<BarrierDetail barriers={barriers} />)
        })}
      </Box>
    </Box >
  )
}
export default Barriers