import { Box, Button, Typography, Modal, TextField, Label, FormLabel, Checkbox, FormGroup, FormControlLabel, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { BsCheckCircleFill } from "react-icons/bs";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./report";
import { setExport, setExportLoader } from "../../../../redux/actions/nlaactions";
import store from "../../../../redux/store/store";
import { MOCKUP_ALLINSIGHTS_CATEGORY } from "../../../../constants/constant";
import { async } from "q";
import axios from "../../../../http/axios";


const style = {
    container: {
        top: '50%',
        left: '50%',
        alignItem: 'center',
        transform: 'translate(-50%, -50%)',
        width: 1215,
        height: 545,
        bgcolor: '#ffffff',
        boxShadow: 15,
        borderRadius: 10,
        position: 'fixed',
    },
    Additem: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        // textAlign: 'center',
        margin: '20px 30px',
        height: 443,
        gap: 40
    },
    close: {
        position: 'absolute',
        cursor: 'pointer',
        top: -20,
        right: -15,
        width: 55,
        height: 55,
        backgroundColor: '#fda945',
        borderColor: '#fff',
        borderRadius: 30,
        border: '2px solid #fff',
        textAlign: 'center'
    },
    spinner: {
        display: 'flex', position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        alignItems: 'center',
        justifyContent: 'center',
    }

};



const ExportInsights = () => {
    const [open, setOpen] = React.useState(true);
    const [email, setEmail] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [category, setCategory] = React.useState(MOCKUP_ALLINSIGHTS_CATEGORY || []);
    const { state } = useLocation();
    const navigate = useNavigate();
    const handleClose = () => navigate(-1);

    const sendEmaileOrExport = async (isExport) => {
        setIsLoading(true)
        store.dispatch(setExport(category))
        setTimeout(async () => {
            let element = document.querySelector("#pdfReport")
            const canvas = await html2canvas(element, {
                allowTaint: true,
                useCORS: true,
                logging: false,
                height: 6500,
                windowWidth: 2200,
                width: 2200,
                windowHeight: 6500,
                scrollX: 0,
                scrollY: 15
            });
            const imgData = canvas.toDataURL();
            const pdf = new jsPDF('p', 'pt', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const imgY = 0;
            pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio, '', 'FAST');
            if (isExport) {
                pdf.save(`${state?.fileName.replace(' ', '')}.PDF`)
                setIsLoading(false)
            }
            else {
                var blob = pdf.output('blob');
                let file = new File([blob], `${state?.fileName.replace(' ', '')}.pdf`, { type: "application/pdf" })
                // var FormData = require('form-data');
                var data = new FormData();
                data.append('rochepdf', file);
                data.append('email', email);
                data.append('countryid', state?.country_id);
                data.append('region', state.selectedRegions.value);
                data.append('duration', state.selectedDuration.name);
                data.append('duration_month', state.selectedMonth.value);


                var config = {
                    method: 'post',
                    url: '/misc/reportupload',
                    data: data,
                    headers: {
                        // 'Content-Type': 'multipart/form-data',
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                };

                axios(config)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        if (!response?.data?.status) {
                            alert(response?.data?.message)
                            setIsLoading(false)
                            return
                        }
                        navigate(-1)

                    })
                    .catch(function (error) {
                        console.log(error);
                        setIsLoading(false)
                    });

            }





        }, [5000])
        // store.dispatch(setExport([]))
    };
    const handleCheckChange = (checked, i) => {
        setCategory((category || []).map((categoryItem, j) => {
            return ({
                ...categoryItem, isChecked: i == j ? checked : categoryItem.isChecked
            })
        }))
    }
    const handleCheckChangeAll = (event) => {
        const { target: { checked } } = event;
        setCategory((category || []).map((categoryItem) => {
            return ({
                ...categoryItem, isChecked: checked
            })
        }))
    }

    useEffect(() => {
        store.dispatch(setExport([]))
    }, [])

    // const test = (event) => {
    //     console.log("test1",)

    //     var blob = pdf.output('blob');
    //     // var FormData = require('form-data');
    //     var data = new FormData();
    //     data.append('rochepdf', event.target.files[0]);
    //     data.append('email', 'raviraw299@gmail.com');
    //     data.append('countryid', '1');
    //     data.append('region', '1');
    //     data.append('duration', 'Quarterly');
    //     data.append('duration_month', '1');


    //     var config = {
    //         method: 'post',
    //         url: '/misc/reportupload',
    //         data: data,
    //         headers: {
    //             // 'Content-Type': 'multipart/form-data',
    //             'Accept': 'application/x-www-form-urlencoded',
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         }
    //     };

    //     axios(config)
    //         .then(function (response) {
    //             console.log(JSON.stringify(response.data));
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 1500 }}
            >
                <Box sx={style.container}>
                    <Box style={style.close}>
                        <MdOutlineClose size={30} style={{ color: "#fff", marginTop: 10 }} onClick={handleClose}></MdOutlineClose>
                    </Box>
                    {isLoading && <Box sx={style.spinner}>
                        <CircularProgress />
                    </Box>}
                    <Box sx={{ margin: '50px' }}>
                        <Typography id="modal-modal-description" sx={{ mt: 2, color: '#546FE6', fontSize: 28, fontFamily: 'Montserrat-Bold' }} >
                            {'Select Category'}
                        </Typography>
                        <Box style={style.Additem}>
                            <FormGroup className="d-flex flex-row gap-3">
                                <FormControlLabel className='d-flex flex-row col-3 align-items-center gap-2'
                                    control={
                                        < Checkbox
                                            checked={category.filter(e => e.isChecked).length == category.length}
                                            icon={< CircleUnchecked sx={{ color: "#999999" }
                                            } />}
                                            checkedIcon={< CheckCircleIcon sx={{
                                                color: "#009E6C"
                                            }}
                                            />}
                                            onChange={handleCheckChangeAll}
                                        />
                                    }
                                    label={<Typography variant="body1" style={{ color: "#141821", fontSize: '16px', fontFamily: "Poppins-Regular" }}>
                                        {'All'} </Typography>}
                                />
                                {(category || []).map((categoryItem, i) => {
                                    return (
                                        <FormControlLabel className={`d-flex flex-row ${(i == 2 || i == 6) ? 'col-2' : 'col-3'
                                            } align - items - center gap - 2`}
                                            control={
                                                < Checkbox
                                                    checked={categoryItem?.isChecked}
                                                    onChange={(e) => {
                                                        const { target: { checked } } = e;
                                                        handleCheckChange(checked, i)
                                                    }
                                                    }
                                                    icon={< CircleUnchecked sx={{ color: "#999999" }
                                                    } />}
                                                    checkedIcon={< CheckCircleIcon sx={{
                                                        color: "#009E6C"
                                                    }}

                                                    />}
                                                />
                                            }
                                            label={< Typography variant="body1" style={{ color: "#141821", fontSize: '16px', fontFamily: "Poppins-Regular" }}>
                                                {categoryItem?.name} </Typography>}
                                        />
                                    )
                                })}
                                {/* <FormControlLabel className="d-flex flex-row col-3 align-items-center gap-2"
                                    control={
                                        <Checkbox
                                            icon={<CircleUnchecked sx={{ color: "#999999" }} />}
                                            checkedIcon={<CheckCircleIcon sx={{
                                                color: "#009E6C"
                                            }}
                                            />}
                                        />
                                    }
                                    label={<Typography variant="body1" style={{ color: "#141821", fontSize: '16px', fontFamily: "Poppins-Regular" }}>
                                        Barriers </Typography>}
                                />
                                <FormControlLabel className="d-flex flex-row col-3 align-items-center gap-2"
                                    control={
                                        <Checkbox
                                            icon={<CircleUnchecked sx={{ color: "#999999" }} />}
                                            checkedIcon={<CheckCircleIcon sx={{
                                                color: "#009E6C"
                                            }}
                                            />}
                                        />
                                    }
                                    label={<Typography variant="body1" style={{ color: "#141821", fontSize: '16px', fontFamily: "Poppins-Regular" }}>
                                        Travel </Typography>}
                                />
                                <FormControlLabel className="d-flex flex-row col-2 align-items-center gap-2"
                                    control={
                                        <Checkbox
                                            icon={<CircleUnchecked sx={{ color: "#999999" }} />}
                                            checkedIcon={<CheckCircleIcon sx={{
                                                color: "#009E6C"
                                            }}
                                            />}
                                        />
                                    }
                                    label={<Typography variant="body1" style={{ color: "#141821", fontSize: '16px', fontFamily: "Poppins-Regular" }}>
                                        Process </Typography>}
                                />
                                <FormControlLabel className="d-flex flex-row col-3 align-items-center gap-2"
                                    control={
                                        <Checkbox
                                            icon={<CircleUnchecked sx={{ color: "#999999" }} />}
                                            checkedIcon={<CheckCircleIcon sx={{
                                                color: "#009E6C"
                                            }}
                                            />}
                                        />
                                    }
                                    label={<Typography variant="body1" style={{ color: "#141821", fontSize: '16px', fontFamily: "Poppins-Regular" }}>
                                        Risk Factors </Typography>}
                                />
                                <FormControlLabel className="d-flex flex-row col-3 align-items-center gap-2"
                                    control={
                                        <Checkbox
                                            icon={<CircleUnchecked sx={{ color: "#999999" }} />}
                                            checkedIcon={<CheckCircleIcon sx={{
                                                color: "#009E6C"
                                            }}
                                            />}
                                        />
                                    }
                                    label={<Typography variant="body1" style={{ color: "#141821", fontSize: '16px', fontFamily: "Poppins-Regular" }}>
                                        App Usage </Typography>}
                                />
                                <FormControlLabel className="d-flex flex-row col-3 align-items-center gap-2"
                                    control={
                                        <Checkbox
                                            icon={<CircleUnchecked sx={{ color: "#999999" }} />}
                                            checkedIcon={<CheckCircleIcon sx={{
                                                color: "#009E6C"
                                            }}
                                            />}
                                        />
                                    }
                                    label={<Typography variant="body1" style={{ color: "#141821", fontSize: '16px', fontFamily: "Poppins-Regular" }}>
                                        Completion Rate </Typography>}
                                />
                                <FormControlLabel className="d-flex flex-row col-2 align-items-center gap-2"
                                    control={
                                        <Checkbox
                                            icon={<CircleUnchecked sx={{ color: "#999999" }} />}
                                            checkedIcon={<CheckCircleIcon sx={{
                                                color: "#009E6C"
                                            }}
                                            />}
                                        />
                                    }
                                    label={<Typography variant="body1" style={{ color: "#141821", fontSize: '16px', fontFamily: "Poppins-Regular" }}>
                                        Referrals </Typography>}
                                />
                                <FormControlLabel className="d-flex flex-row col-3 align-items-center gap-2"
                                    control={
                                        <Checkbox
                                            icon={<CircleUnchecked sx={{ color: "#999999" }} />}
                                            checkedIcon={<CheckCircleIcon sx={{
                                                color: "#009E6C"
                                            }}
                                            />}
                                        />
                                    }
                                    label={<Typography variant="body1" style={{ color: "#141821", fontSize: '16px', fontFamily: "Poppins-Regular" }}>
                                        Deaths </Typography>}
                                /> */}

                            </FormGroup>

                            <Box>
                                <Typography style={{ color: "#110345", fontSize: '20px', fontFamily: "Montserrat-Bold", marginBottom: "12px" }}>
                                    Email addresses </Typography>
                                <FormLabel id="keywords-label" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Email Address</FormLabel>
                                <TextField placeholder="abc@roche.com, " id="keywords" fullWidth variant="standard" style={{ color: '#464A53', fontSize: 18, fontFamily: 'Poppins-Regular' }} InputLabelProps={{ style: { color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }, }}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }} />
                            </Box>

                            <Box className="d-flex flex-row gap-4 justify-content-end">
                                <Button className="d-flex flex-row col-2"
                                    variant="contained"
                                    style={{
                                        height: 45,
                                        marginBottom: 4,
                                        marginTop: 20,
                                        borderRadius: 4,
                                        border: "2px solid #546FE6",
                                        color: "#546FE6",
                                        background: "#FFFFFF",
                                        textTransform: 'none',
                                        fontSize: 18,
                                        fontFamily: 'Poppins-SemiBold'
                                    }}
                                    onClick={() => sendEmaileOrExport(false)}
                                >
                                    Send on Mail
                                </Button>

                                <Button className="d-flex flex-row col-2"
                                    variant="contained"
                                    style={{
                                        height: 45,
                                        marginBottom: 4,
                                        // width: '-webkit-fill-available',
                                        marginTop: 20,
                                        borderRadius: 4,
                                        background: "#546FE6",
                                        textTransform: 'none',
                                        fontSize: 18,
                                        fontFamily: 'Poppins-SemiBold'
                                    }}
                                    onClick={() => sendEmaileOrExport(true)}
                                >
                                    Download
                                </Button>
                                {/* <input type="file" name="file" onChange={test} /> */}



                                {/* <Button className="d-flex flex-row col-2"
                                    variant="contained"
                                    style={{
                                        height: 45,
                                        marginBottom: 4,
                                        // width: '-webkit-fill-available',
                                        marginTop: 20,
                                        borderRadius: 4,
                                        background: "#546FE6",
                                        textTransform: 'none',
                                        fontSize: 18,
                                        fontFamily: 'Poppins-SemiBold'
                                    }}
                                    onClick={() =>{navigate("/pdf")}}
                                >
                                    Download
                                </Button> */}
                            </Box>


                        </Box>
                    </Box>
                </Box>
            </Modal >
        </>
    );
};
export default ExportInsights