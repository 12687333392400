import * as React from "react";
import { useState } from "react";
import { Box, Grid, InputLabel } from "@mui/material"; import { PaperJournyPhase } from "./phase";
import { ReactComponent as ScreeningImg } from '../../../../../assets/webapp_icon/Screening.svg';
import { ReactComponent as DiagnosisImg } from '../../../../../assets/webapp_icon/Diagnosis.svg';
import { ReactComponent as InvestigationImg } from '../../../../../assets/webapp_icon/Investigation.svg';
import { ReactComponent as TreatmentplanningImg } from '../../../../../assets/webapp_icon/Treatmentplanning.svg';
import { ReactComponent as TreatmentinitiationImg } from '../../../../../assets/webapp_icon/Treatmentinitiation.svg';
import { useLocation } from "react-router-dom";
import store from "../../../../../redux/store/store";
import "../../../../../assets/css/patientJourney.css"
import { patientJourneyImages } from "../../../../../constants/constant";


const data = {
    estimatedDays: 20,
    actual: 26,
}
const _MOCKUP = [
    {
        image: <ScreeningImg />,
        phaseName: 'Screening',
        patients: {
            value: 'NA',
        },
        estinamtedDays: {
            value: 'NA',
            difference: 'NA',
            isUp: true
        },
        data: {
            estimatedDays: 'NA',
            actual: 'NA',
        }
    },
    {
        image: <DiagnosisImg />,
        phaseName: 'Diagnosis',
        patients: {
            value: 'NA',
        },
        estinamtedDays: {
            value: 'NA',
            difference: 'NA',
            isUp: false
        },
        data: {
            estimatedDays: 'NA',
            actual: 'NA',
        }
    },
    {
        image: <InvestigationImg />,
        phaseName: 'Investigation',
        patients: {
            value: 'NA',
        },
        estinamtedDays: {
            value: 'NA',
            difference: 'NA',
            isUp: true
        },
        data: {
            estimatedDays: 'NA',
            actual: 'NA',
        }
    }
    ,
    {
        image: <TreatmentplanningImg />,
        phaseName: 'Treatment Planning',
        patients: {
            value: 'NA',
        },
        estinamtedDays: {
            value: 'NA',
            difference: 'NA',
            isUp: true
        },
        data: {
            estimatedDays: 'NA',
            actual: 'NA',
        }
    },
    {
        image: <TreatmentinitiationImg />,
        phaseName: 'Treatment Initiation',
        patients: {
            value: 'NA',
        },
        estinamtedDays: {
            value: 'NA',
            difference: 'NA',
            isUp: true
        },
        data: {
            estimatedDays: 'NA',
            actual: 'NA',
        }
    }
]

const JourneyPhase = () => {
    const [journeyPhase, setJourneyPhase] = useState([])
    console.log(journeyPhase,"journeyPhasejourneyPhase")
    React.useEffect(() => {
        let { journeyPhase } = store?.getState()?.nlaReducer;
        journeyPhase = (journeyPhase || []).map((phase, index) => {
            console.log(phase,"phasephasephasephasephase")
            return ({
                image: patientJourneyImages[index],
                phaseName: phase?.journey_stage_name,
                patients: {
                    value: phase?.total_patient,
                },
                estinamtedDays: {
                    value: parseInt(phase?.standard_days),
                    difference: Math.abs(parseInt(phase?.standard_days) - parseInt(phase?.actual_days)),
                    isUp: parseInt(phase?.standard_days) < parseInt(phase?.actual_days)
                },
                data: {
                    estimatedDays: parseInt(phase?.standard_days),
                    actual: parseInt(phase?.actual_days),
                },
                start_process: phase?.start_process,
                end_process: phase?.end_process
            })
        })

        setJourneyPhase(journeyPhase)
    }, [store?.getState()?.nlaReducer?.journeyPhase])
    const journeyPhases = journeyPhase || []
    // [
    //     {
    //         image: <ScreeningImg />,
    //         phaseName: 'Screening',
    //         patients: {
    //             value: 237,
    //         },
    //         estinamtedDays: {
    //             value: 20,
    //             difference: 6,
    //             isUp: true
    //         },
    //         data: {
    //             estimatedDays: 20,
    //             actual: 26,
    //         }
    //     },
    //     {
    //         image: <DiagnosisImg />,
    //         phaseName: 'Diagnosis',
    //         patients: {
    //             value: 237,
    //         },
    //         estinamtedDays: {
    //             value: 14,
    //             difference: 6,
    //             isUp: false
    //         },
    //         data: {
    //             estimatedDays: 14,
    //             actual: 8,
    //         }
    //     },
    //     {
    //         image: <InvestigationImg />,
    //         phaseName: 'Investigation',
    //         patients: {
    //             value: 237,
    //         },
    //         estinamtedDays: {
    //             value: 20,
    //             difference: 6,
    //             isUp: true
    //         },
    //         data: {
    //             estimatedDays: 20,
    //             actual: 26,
    //         }
    //     }
    //     ,
    //     {
    //         image: <TreatmentplanningImg />,
    //         phaseName: 'Treatment Planning',
    //         patients: {
    //             value: 237,
    //         },
    //         estinamtedDays: {
    //             value: 20,
    //             difference: 6,
    //             isUp: true
    //         },
    //         data: {
    //             estimatedDays: 20,
    //             actual: 26,
    //         }
    //     },
    //     {
    //         image: <TreatmentinitiationImg />,
    //         phaseName: 'Treatment Initiation',
    //         patients: {
    //             value: 237,
    //         },
    //         estinamtedDays: {
    //             value: 20,
    //             difference: 6,
    //             isUp: true
    //         },
    //         data: {
    //             estimatedDays: 20,
    //             actual: 26,
    //         }
    //     }
    // ]


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: '30px',
                flexWrap: "wrap",
            }}
        >
            <InputLabel
                style={{
                    fontSize: 24,
                    fontFamily: "Poppins-Medium",
                    color: "#554886",
                    marginLeft: '8px'
                }}
            >
                Journey Phase
            </InputLabel>
            <Box
                className="section-container"
                sx={{
                    "& > :not(style)": {
                        m: 1,
                        flex: 1,
                        // height: 650,
                        minWidth: 360
                    },
                }}

            >
                {(journeyPhases || []).map((journeyPhase, index) => {
                    return (
                        // <Grid item
                        //     xs={12} sm={6} md={4} lg={3} xl={2.4}
                        //     key={index}  >
                        <PaperJournyPhase journeyPhase={journeyPhase} />
                        // </Grid>
                    )
                })}
            </Box>
        </Box>
    );
}
export default JourneyPhase