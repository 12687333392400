import * as React from "react";
import {
  Grid,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import { Box, Link } from "@mui/material";
import { ProgressBar } from 'react-bootstrap';
import "../../../../../assets/css/patientJourney.css";
import store from "../../../../../redux/store/store";
import { useState } from "react";
import _ from "lodash";



const BarrierDetail = (props) => {
  const barrierProcess = props.barriers?.barrier_category

  // || [{
  //   resolved: 110,
  //   occurence: 450,
  //   name: 'Transport',
  //   _id: '1',
  //   bartop: 50,
  //   barbottom: 100,
  //   expense: '5k'
  // }]



  return (
    <>
      <Paper
        elevation={0}
        sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1, flex: 1 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 20px",
            minWidth: 360,
          }}
        >


          {/* <Box
            style={{
              display: "flex",
              flexDirection: "row",
              //alignItems: "center",
              justifyContent: "space-between",
              padding: "20px 0",
            }}
          >
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <InputLabel
                style={{
                  fontSize: 13,
                  fontFamily: "Poppins-Regular",
                  color: "#8E8E8E",
                  paddingBottom: 40
                }}
              >
                {"Process"}
              </InputLabel>

              {(barrierProcess || []).map((processItem, i) => {
                return (<Typography
                  style={{
                    fontSize: 14,
                    fontFamily: "Poppins-Medium",
                    color: "#838383",
                    paddingBottom: 20
                  }}
                >
                  {processItem.name}
                </Typography>)
              })}
              
            </Box>

            <Box style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <InputLabel
                style={{
                  fontSize: 13,
                  fontFamily: "Poppins-Regular",
                  color: "#8E8E8E",
                  paddingBottom: 20
                }}
              >
                {"Resolved/Occurence"}
              </InputLabel>

              {(barrierProcess || []).map((processItem, i) => {
                return (
                  <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5
                  }}>
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <ProgressBar variant="progress1" now={processItem?.bartop} className='progress-barriers' />
                      <ProgressBar variant="progress2" now={processItem?.barbottom} className='progress-barriers' />
                    </Box>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontFamily: "Poppins-Semibold",
                        color: "#555555",
                      }}
                    >
                      {processItem?.resolved + "/" + processItem?.occurence}
                    </Typography>
                  </Box>
                )
              })}
            </Box>

            <Box style={{ display: "flex", flexDirection: "column", gap: 20, alignItems: "end" }}>
              <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <InputLabel
                  style={{
                    fontSize: 13,
                    fontFamily: "Poppins-Regular",
                    color: "#8E8E8E",
                    //paddingBottom: 5
                  }}
                >
                  {"Expense"}
                </InputLabel>
                <InputLabel
                  style={{
                    fontSize: 13,
                    fontFamily: "Poppins-Regular",
                    color: "#8E8E8E",
                    //paddingBottom: 5
                  }}
                >
                  {"(USD)"}
                </InputLabel>
              </Box>
              {(barrierProcess || []).map((processItem, i) => {
                return (
                  <Typography
                    style={{
                      fontSize: 14,
                      fontFamily: "Poppins-Medium",
                      color: "#222222",
                    }}
                  >
                    {processItem.expense}
                  </Typography>
                )
              })}
            </Box>
          </Box> */}
          <Grid container style={{ paddingRight: 10 }}>
            <Grid container style={{ paddingBottom: '8px', paddingTop: "20px" }} >
              <Grid lg={4.8} sm={4.8} xs={4.8} item >
                <div className='text-head' style={{textAlign: 'right', width: 115}}>
                  Barrier
                </div>
              </Grid>
              {/* <Grid lg={3} sm={3} xs={3} item >
                <div className='text-head'>

                </div>
              </Grid> */}
              <Grid lg={5.2} sm={5.2} xs={5.2} item >
                <div className='text-head'>
                  Resolved/Others
                </div>
              </Grid>
              <Grid lg={2} sm={2} xs={2} item >
                <div className='text-head' style={{flexWrap: 'wrap', display: 'flex', textAlign: 'right'}} >
                  Expense (USD)
                </div>
              </Grid>
            </Grid>
            {(barrierProcess || []).map((barrierItem, j) => (
              <Grid container style={{ marginBottom: '16px' }} key={j} spacing={2}>
                <Grid lg={4.8} sm={4.8} xs={4.8} item style={{paddingLeft: 5}}>
                  <div className="text-detail" style={{width: 125, textAlign: 'right', wordBreak: 'break-word'}}>
                    {barrierItem?.name}
                  </div>
                </Grid>
                <Grid lg={5.2} sm={5.2} xs={5.2} item>
                  <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                    <div style={{width: 'calc(100%)'}}>
                      <ProgressBar variant="progress1" now={parseInt(barrierItem.bartop || 0)} className='progressbar'  />
                      <ProgressBar variant="progress2" now={parseInt(barrierItem.barbottom || 0)} className='progressbar' />
                    </div>
                    <div className="text-detail" style={{width: '30px'}}>
                      {barrierItem?.resolved + "/" + barrierItem?.occurence}
                    </div>
                  </div>
                </Grid>
                
                <Grid lg={2} sm={2} xs={2} item style={{width: 53}}>
                  <div className="text-detail" style={{textAlign: "right"}}>
                    {barrierItem?.expense || 0}
                  </div>
                </Grid>
              </Grid>
            ))}
          </Grid>
          {
            _.isEmpty(barrierProcess) && <a style={{ textAlign: 'center' }}>No Data</a>
          }


        </Box>
      </Paper>
    </>
  )
}
export default BarrierDetail