import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import {
    Avatar,
    Box,
    Button,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


import * as _ from "lodash";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BsArrowLeft } from "react-icons/bs";
import { BiSolidDownArrow } from "react-icons/bi";
import TopBarImage from '../../assets/images/navigator/topbar.png'
import { setLogout } from "../../redux/actions/actions";
import store from "../../redux/store/store";
import Helper from '../../assets/images/helper_white.svg';

const theme = createTheme({
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#173A5E',
            secondary: '#46505A',
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h2',
                    h2: 'h2',
                    h3: '12',
                    h4: 'h2',
                    h5: 'h2',
                    h6: 'h6',
                    subtitle1: 'h2',
                    subtitle2: 'p',
                    body1: 'span',
                    body2: 'span',
                },
                fontFamily: 'Montserrat-Bold',
                color: '#FFFFFF'
            },
        },
    },
});

const Style = {
    Title: {
        fontSize: 24,
        fontFamily: "Montserrat-Bold",
        color: "#ffffff",
    },
};



const TopNavigation = (props) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { loginInfo } = useSelector(state => state.auth)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onExit = () => {
        store.dispatch(setLogout());
        localStorage.clear();
        navigate('/login', { replace: true });
    };
    const _onTopNavButtonCallBack = () => {
        props.button._onTopNavButtonCallBack()
    }
    return (
        <ThemeProvider theme={theme}>
            <Box
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#546FE6",
                    backgroundImage: `url(${TopBarImage})`,
                    opacity: 0.70,
                    height: 96,
                    padding: '0px 15px',
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "0 20px",
                        gap: 20,
                    }}
                >
                    <BsArrowLeft
                        style={{
                            height: 25,
                            width: 25,
                            color: "#fff",
                            cursor: 'pointer',
                        }}

                        onClick={() => navigate(props?.navPath || -1, { replace: true })}
                    />
                    <span style={{display: 'flex', alignItems: 'center'}}>
                        <InputLabel style={Style.Title}>
                            {props.title}
                        </InputLabel>
                        {props?.eyeIcon && <Tooltip title={props?.eyeIcon} arrow placement="right">
                            <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                        </Tooltip>}
                    </span>

                </Box>
                {props.children}

                <Box style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                    {
                        props?.button?.name &&
                        <Button
                            style={{width: props?.button?.width || "auto", height: '45px', margin: '0px 50px', color: "#546FE6", background: '#FFFFFF', fontFamily: 'Poppins-SemiBold', fontSize: 18, textTransform: "none", /*minWidth: 180*/ }}
                            variant="contained"
                            startIcon={
                                <Avatar src={props?.button?.startIcon} style={{ width: 35, height: props?.button?.height || 35, padding: 5 }} />
                            }
                            onClick={_onTopNavButtonCallBack}
                        >
                            {props?.button?.name}
                        </Button>
                    }
                    <Box style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                        <Box style={{ alignItems: "center" }}>
                            <Button
                                id="basic-button"
                                onClick={handleClick}
                                style={{ color: "#fff", padding: 0, marginRight: 10, fontSize: 18, fontFamily: 'Poppins-Bold', color: '#ffffff', textTransform: 'none', }}
                                endIcon={<BiSolidDownArrow size={10} />}
                            >
                                {loginInfo?.user_name}
                            </Button>
                            <Menu
                                id="basic-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                style={{ minWidth: 150, marginTop: 30 }}
                            >
                                {/* <MenuItem style={{ minWidth: 150 }} onClick={handleClose}>
                                    Profile
                                </MenuItem>
                                <MenuItem style={{ minWidth: 150 }} onClick={handleClose}>
                                    My account
                                </MenuItem> */}
                                <MenuItem style={{ minWidth: 150 }} onClick={onExit}>
                                    Sign out
                                </MenuItem>
                            </Menu>
                        </Box>
                        <InputLabel style={{ fontSize: 13, fontFamily: 'Poppins-Regular', color: '#ffffff' }}>
                            {loginInfo?.user_role}
                        </InputLabel>
                    </Box>
                </Box>

            </Box >
        </ThemeProvider>
    )
}
export { TopNavigation }