import { Outlet } from "react-router-dom";


import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { useEffect, useState } from "react";
import "../../../assets/css/theme.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { API, MOCKUP_DURATION, MOCKUP_MONTH, MOCKUP_MONTH_HALFYEARLY, MOCKUP_MONTH_QAURTERLY, MOCKUP_MONTH_YEARLY, MOCKUP_YEAR } from "../../../constants/constant";
import "../../../assets/css/patientJourney.css";
import PatientJourneyBox from "./patient-journey-box"
import { TopNavigation } from "../../../common/control/topnavigation";
import AllInsightsI from '../../../assets/images/navigator/allinsights.png'
import axios from '../../../http/axios'
import store from "../../../redux/store/store";
import { setJourneyDropdownData, setPatientJourneyData, setPatientJourneyLoader } from "../../../redux/actions/rocheactions";
import { useSelector } from "react-redux";
import CommonDropDown from "../../../common/control/commondropdown";
import { CustomLoader } from "../../../common/control/customloader";
import { setRegion } from "../../../redux/actions/actions";

const Style = {

  dropdownTitle: {
    fontSize: 14,
    fontFamily: "Poppins-Regular",
    color: "#464A53",
  },
  select: {
    height: 45,
    background: "#ffffff",
    borderRadius: 4,
    border: '1px solid #f4f6fa'
  },
  loader: {
    display: "flex",
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  dropdownTitle: {
    fontSize: 14,
    fontFamily: "Poppins-Regular",
    color: "#464A53",
    paddingLeft:2.5
  },
  select: {
      display: 'flex',
      minWidth: 170,
      height: 46.48,
      background: "#ffffff",
      borderRadius: 4,
      border: '1px solid #f4f6fa',
      //   zIndex: 2000
  }
};
const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: '12',
          h4: 'h2',
          h5: 'h2',
          h6: 'h6',
          subtitle1: 'h2',
          subtitle2: 'p',
          body1: 'span',
          body2: 'span',
        },
        fontFamily: 'Montserrat-Bold',
        color: '#FFFFFF'
      },
    },
  },
});

const PatientJourney = (props) => {
  const { isLoading, dropdownData, journeyTotalDays } = useSelector(state => state.rocheReducer)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [onLoadedApi, setonLoadApi] = useState(true)
  const { country_id, loginInfo } = useSelector(state => state.auth)
  let { regions_list, cancerTypes_list } = store?.getState()?.auth;

  const [regions, setRegions] = useState({
    list: [],
    selectedItems: [],
    name: '',
    error: false,
  });
  const [cancerTypes, setCancerTypes] = useState({
      list: [],
      selectedItems: [],
      name: '',
      error: false,
  });

  const [duration, setDuration] = useState({
      list: MOCKUP_DURATION,
      selectedItems: [],
      name: "",
      error: false,
  });
  const [month, setMonth] = useState({
      list: MOCKUP_MONTH,
      selectedItems: [],
      name: "",
      error: false,
  });
  const [year, setYear] = useState({
      list: MOCKUP_YEAR,
      selectedItems: [],
      name: "",
      error: false,
  });
  const [queryParams, setQueryParams] = useState({});



  useEffect(() => {
    // Parse query parameters from the URL
    const searchParams = new URLSearchParams(window.location.search);

    // Initialize an empty object to store query parameters
    const params = {};

    // Iterate over each query parameter and add it to the params object
    for (const [key, value] of searchParams) {
      params[key] = value;
    }

    // Update state with the parsed query parameters
    setQueryParams(params);
  }, []);


  const navigate = useNavigate();
  const _onTopNavButtonCallBack = () => {
    navigate(`/reports/allinsights${window.location.search}`);
  }

  const getRocheCancerPatientJourneryReport = (data) => {    
    var config = {
      method: 'get',
      url: `${API.getPatientJourney}?cancer_type=${data?.cancerTypes?.selectedItems[0]}&duration=${data?.duration?.name}&duration_month=${data?.month?.selectedItems[0]}&region=${data?.regions?.selectedItems[0]}&year=${data?.year?.name}&country_id=${country_id}&option=1`
    };
    store.dispatch(setPatientJourneyLoader(true))
    axios.request(config)
      .then(function (response) {
        let patientData = {...response?.data?.data}
        setPageLoaded(true)
        if (patientData?.data?.length) {
          store.dispatch(setPatientJourneyData(patientData))
        }
      })
      .catch(function (error) {
        console.log(error);
        store.dispatch(setPatientJourneyLoader(false))
      });
  }

  const renderMonthList = (type) => {
    if(type === "2") {
      return MOCKUP_MONTH_QAURTERLY
    }
    if(type === "3") {
      return MOCKUP_MONTH_HALFYEARLY
    }
    if(type === "4") {
      return MOCKUP_MONTH_YEARLY
    }
    return MOCKUP_MONTH
  }

  const setMonthData = () => {
    if (duration) {
        switch (duration.name) {
          case duration.list[0].name:
              setMonth({
                ...month,
                list: MOCKUP_MONTH,
                // selectedItems: [MOCKUP_MONTH.find(e => e._id == new Date().getMonth() + 1).value],
                // name: MOCKUP_MONTH.find(e => e._id == new Date().getMonth() + 1).name,
                // error: false,
              })
              break
          case duration.list[1].name:
              setMonth({
                ...month,
                list: MOCKUP_MONTH_QAURTERLY,
                // selectedItems: [1],
                // name: MOCKUP_MONTH_QAURTERLY[0].name,
                // error: false,
              })
              break
          case duration.list[2].name:
              setMonth({
                ...month,
                list: MOCKUP_MONTH_HALFYEARLY,
                // selectedItems: [1],
                // name: MOCKUP_MONTH_HALFYEARLY[0].name,
                // error: false,
              })
              break
          case duration.list[3].name:
              setMonth({
                ...month,
                list: MOCKUP_MONTH_YEARLY,
                // selectedItems: [1],
                // name: MOCKUP_MONTH_YEARLY[0].name,
                // error: false,
              })
              break
        }
    }
}

  useEffect(() => {
    if(window.location.search) {
      if(regions_list?.length && cancerTypes_list?.length) {
        setRegions({
          list: regions_list,
          selectedItems: [queryParams?.region],
          name: regions_list?.find(itm => itm.value === queryParams?.region)?.name,
          error: false,
        })
        setCancerTypes({
            list: cancerTypes_list,
            selectedItems: [queryParams?.cancerTypes],
            name: cancerTypes_list?.find(itm => itm.value === queryParams?.cancerTypes)?.name,
            error: false,
        });
        setMonth({
          list: MOCKUP_MONTH,
          selectedItems: [queryParams?.month],
          name: renderMonthList(queryParams?.duration).find(itm => itm.value === queryParams?.month)?.name,
          error: false,
        })
        setDuration({
          list: MOCKUP_DURATION,
          selectedItems: [queryParams?.duration],
          name: MOCKUP_DURATION.find(itm => itm.value === queryParams?.duration)?.name,
          error: false,
        });
        setYear({
          list: MOCKUP_YEAR,
          selectedItems: [queryParams?.year],
          name: MOCKUP_YEAR.find(itm => itm.value === queryParams?.year)?.name,
          error: false,
        });
      }
    } else {
      if(regions_list?.length && cancerTypes_list?.length) {
        setRegions({
          list: regions_list,
          selectedItems: [0],
          name: regions_list?.[0]?.name,
          error: false,
        })
        setCancerTypes({
            list: cancerTypes_list,
            selectedItems: [localStorage.getItem("selectedCancerType") ? localStorage.getItem("selectedCancerType") : 1001],
            name: cancerTypes_list?.[0]?.name,
            error: false,
        });
        setMonth({
          list: MOCKUP_MONTH,
          selectedItems: [MOCKUP_MONTH.find(e => e._id == new Date().getMonth() + 1).value],
          name: MOCKUP_MONTH.find(e => e._id == new Date().getMonth() + 1).name,
          error: false,
        })
        setDuration({
          list: MOCKUP_DURATION,
          selectedItems: [1],
          name: MOCKUP_DURATION[0].name,
          error: false,
        });
        setYear({
          list: MOCKUP_YEAR,
          selectedItems: [MOCKUP_YEAR.find(e => e.name == new Date().getFullYear())?.value],
          name: MOCKUP_YEAR.find(e => e.name == new Date().getFullYear())?.name,
          error: false,
        });
      }
    }
    
  },[queryParams, regions_list, cancerTypes_list])


  useEffect(() => {
    console.log(queryParams?.duration,"queryParams?.duration")
    let list = MOCKUP_MONTH
    if(queryParams?.duration) {
      if(queryParams?.duration == 2) {
        list = MOCKUP_MONTH_QAURTERLY
      }
      if(queryParams?.duration == 3) {
        list = MOCKUP_MONTH_HALFYEARLY
      }
      if(queryParams?.duration == 3) {
        list = MOCKUP_MONTH_YEARLY
      }
      let found = list.find(itm => itm.value == queryParams?.duration)
      if(found) {
        setMonth({...month, name: found?.name, selectedItems: [found._id], list: list})
      }
    }
  },[window.location.search])

  useEffect(() => {
    if(duration) {
      setMonthData()
    }
  },[duration])


  useEffect(() => {
    if(regions?.name && cancerTypes?.name && month?.name && duration?.name && year?.name && onLoadedApi && country_id) {
      store.dispatch(setJourneyDropdownData({ regions, cancerTypes, duration, month, year }))
      getRocheCancerPatientJourneryReport({regions, cancerTypes, duration, month, year})
      setonLoadApi(false)
    }
  },[regions?.name, cancerTypes?.name, month?.name, duration?.name, year?.name, onLoadedApi])

  useEffect(() => {
    var config = {
      method: 'get',
      url: `${API.getAllRegions}?country_id=${country_id}`
    };
    axios.request(config)
      .then(function (response) {
        const _regions_data = (response?.data?.data || []).map(region => {
          return {
              name: region?.region_name,
              value: region?.region_id?.toString(),
              _id: region?.region_id?.toString(),
          }
        })
        store.dispatch(setRegion(_regions_data))
      
      })
      .catch(function (error) {
        console.log(error);
      });
  },[])

  // useEffect(() => {
  //   if (dropdownData?.regions?.name && dropdownData?.duration?.name && dropdownData?.cancerTypes?.name && dropdownData?.month?.name && dropdownData?.year?.name && country_id && onLoadedApi) {
  //     console.log('252')
  //     getRocheCancerPatientJourneryReport(dropdownData)
  //   }
  // }, [dropdownData?.regions?.name, dropdownData?.duration?.name, dropdownData?.cancerTypes?.name, dropdownData?.month?.name, dropdownData?.year?.name, onLoadedApi])


  // const _dropdownSelectedItems = (item) => {
  //   store.dispatch(setJourneyDropdownData(item))
  // }

  const redirectToBack = () => {
    if(loginInfo?.user_role?.toUpperCase() === "NLA") {
      return '/NLA'
    } else if(loginInfo?.user_role?.toUpperCase() === 'ADMIN') {
      return '/roche-welcome'
    } else if(loginInfo?.user_role?.toUpperCase() === 'PN') {
      return '/welcome'
    }
  }


  const handleRegionChange = (event, { props }) => {
    const {
        target: { value },
    } = event;
    setRegions({
        ...regions,
        selectedItems: typeof value === "string" ? value.split(",") : value,
        name: props.children
    });
  };
  const handleCancerTypeChange = (event, { props }) => {
      const {
          target: { value },
      } = event;
      setCancerTypes({
          ...cancerTypes,
          selectedItems: typeof value === "string" ? value.split(",") : value,
          name: props.children
      });
      
  };
  const handleDurationChange = (event, { props }) => {
      const {
          target: { value },
      } = event;
      setDuration({
          ...duration,
          selectedItems: typeof value === "string" ? value.split(",") : value,
          name: props.children
      });
      setMonth({
          ...month,
          selectedItems: [1]
      });
      
  };
  const handleMonthChange = (event, { props }) => {
      const {
          target: { value },
      } = event;
      setMonth({
          ...month,
          selectedItems: typeof value === "string" ? value.split(",") : value,
          name: props.children
      });
  };
  const handleYearChange = (event, { props }) => {
      const {
          target: { value },
      } = event;
      setYear({
          ...year,
          selectedItems: typeof value === "string" ? value.split(",") : value,
          name: props.children
      });
  };
  

  return (
    <ThemeProvider theme={theme}>

      <Box sx={{ height: "100vh", backgroundColor: "#F6F7FA", overflow: "scroll" }}>
        <TopNavigation
          navPath={redirectToBack()}
          title={'Patient Journey'}
          button={{ startIcon: AllInsightsI, name: 'All Insights',width: '177px', _onTopNavButtonCallBack }}
        />

        <Box
          sx={{
            margin: "20px 40px",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {/* <CommonDropDown callBack={_dropdownSelectedItems} onLoad /> */}

          <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                // margin: '20px',
                marginTop: '10px',
                gap: 4,
                justifyContent: 'space-between'
            }}
        >
          <Box style={{ padding: 0, flex: 1 }}>
              <InputLabel style={Style.dropdownTitle}>
                  Region
              </InputLabel>
              <Select
                  value={regions.selectedItems}
                  onChange={handleRegionChange}
                  style={Style.select}
              >
                  {(regions?.list || []).map((item) => {
                      return <MenuItem value={item.value}>{item.name}</MenuItem>;
                  })}
              </Select>
          </Box>
          <Box style={{ padding: 0, flex: 1 }}>
              <InputLabel id="cancertype" style={Style.dropdownTitle}>
                  Cancer Type
              </InputLabel>
              <Select
                  value={cancerTypes.selectedItems}
                  onChange={handleCancerTypeChange}
                  style={Style.select}
              >
                  {(cancerTypes?.list || []).map((item) => {
                      return <MenuItem value={item.value}>{item.name}</MenuItem>;
                  })}
              </Select>
          </Box>
          <Box style={{ padding: 0, flex: 1 }}>
              <InputLabel id="duration" style={Style.dropdownTitle}>
                  Duration
              </InputLabel>
              <Select
                  value={duration.selectedItems}
                  onChange={handleDurationChange}
                  style={Style.select}
              >
                  {(duration.list || []).map((item) => {
                      return <MenuItem value={item.value}>{item.name}</MenuItem>;
                  })}
              </Select>
          </Box>
          <Box style={{ padding: 0, flex: 1 }}>
              <InputLabel id="month" style={Style.dropdownTitle}>
                  Month
              </InputLabel>
              <Select
                  value={month.selectedItems}
                  onChange={handleMonthChange}
                  style={Style.select}
              >
                  {(month.list || []).map((item) => {
                      return <MenuItem value={item.value} >{item.name}</MenuItem>;
                  })}
              </Select>
          </Box>
          <Box style={props?.yearBoxStyle ? props?.yearBoxStyle: {}}>
              <InputLabel id="year" style={Style.dropdownTitle}>
                  Year
              </InputLabel>
              <Select
                  value={year.selectedItems}
                  onChange={handleYearChange}
                  
                  // IconComponent={(props) => (<AiOutlineDown  {...props}/>)}
                  style={Style.select}
              >
                  {(year.list || []).map((item) => {
                      return <MenuItem value={item.value}>{item.name}</MenuItem>;
                  })}
              </Select>
          </Box>
          <Box sx={{ display: "flex", height: 60, alignItems: "end" }}>
              <Button
                  variant="contained"
                  style={{
                      height: 44, marginBottom: "", width: 109, background: "#546FE6", textTransform: 'none', fontSize: 18, fontFamily: 'Poppins-SemiBold'
                  }}
                  onClick={() => {
                    setonLoadApi(true)
                    localStorage.setItem("selectedCancerType", cancerTypes?.selectedItems[0])
                    navigate(`/reports/patient-journey?region=${regions?.selectedItems[0]}&cancerTypes=${cancerTypes?.selectedItems[0]}&duration=${duration?.selectedItems[0]}&month=${month?.selectedItems[0]}&year=${year?.selectedItems[0]}`, {replace: true});
                  }}
              >
                  Go
              </Button>
          </Box>
          </Box>
          <div className="hr-row">
          </div>

          <box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 5,

            }}
            style={{ paddingTop: 4 }}
          >
            <box
              style={{
                fontSize: 24,
                fontFamily: "Poppins-SemiBold",
                color: "#554886",
              }}
            >
              {journeyTotalDays?.total_days || 0} Days
            </box >

            {!journeyTotalDays?.total_diff_mode ?
              <MdKeyboardArrowUp
                style={{
                  marginLeft: 8,
                  height: 25,
                  width: 25,
                  cursor: "pointer",
                  color: "#E65656",
                }}
              /> :
              <MdKeyboardArrowDown
                style={{
                  marginLeft: 8,
                  height: 25,
                  width: 25,
                  cursor: "pointer",
                  color: "#62E89C",
                }}
              />}
            <box
              style={{
                fontSize: '24px',
                fontFamily: "Poppins-Medium",
                color: !journeyTotalDays?.total_diff_mode ? "#E65656" : "#62E89C",
              }}
            >
              {journeyTotalDays?.total_diff}%
            </box>
          </box>
          {isLoading ? <div style={Style.loader}><CustomLoader /></div> :
            <PatientJourneyBox />
          }
          <Outlet />
        </Box>
      </Box >
    </ThemeProvider >
  );
};

export default PatientJourney;
