import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState, useEffect } from 'react';
import { Box, Link, CircularProgress, Tooltip } from '@mui/material';
import { ReactComponent as Editimg } from '../../assets/webapp_icon/edit.svg';
import { BiEditAlt } from 'react-icons/bi'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ToggleWithText from '../../common/control/togglewithtext';
import Popup from '../NLA/popup';
// import axios from 'axios';
import axios from '../../http/axios';
import { API, CustomWidthTooltip } from '../../constants/constant';
import CommonPagination from '../../common/control/commonpagination';
import { Page } from '@react-pdf/renderer';


const columns = [
    { id: "email", label: "Email Id", minWidth: 170, l: 33 },
    { id: "password", label: "Password", minWidth: 170 },
    { id: "facilityname", label: "Facility", minWidth: 170 },
    { id: "user_status", label: "Status", minWidth: 170 },
    { id: "action", label: "Actions", minWidth: 100, align: "center" },
];

const style = {
    spinner: {
        display: 'flex', position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        alignItems: 'center',
        justifyContent: 'center',
    }
}



const UserTable = (props) => {
    let { page, setPage } = props;
    const { state } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [allPNUserCredentials, setAllPNUserCredentials] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [allFacility, setAllFacility] = useState([]);
    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(1);
    };
    const navigate = useNavigate();
    const onEdit = (rowItem) => {
        if (rowItem?.user_status == "0") return
        navigate('edit-credential', {
            state: {
                title: 'Edit User Credentials!',
                rowItem,
                allFacility
            }
        })
    }

    const updateStatus = (isenable, item) => {
        setIsLoading(true)
        const data = {
            status: item.user_status == 0 && isenable ? 1 : 0,
            user_id: parseInt(item.user_id)
        };
        axios.put(`${API.updateNlaPnUserStatus}`, data)
            .then((res) => {
                console.log("Data updated successfully:", res.data);
                showAllPNUsers()
                navigate("/user-credential");
                setIsLoading(false)
            })
            .catch((error) => {
                console.error("An error occurred:", error);
                setIsLoading(false)
            });
    }

    useEffect(() => {
        if (!state)
        showAllPNUsers()
    }, [props?.debounceQuery, page, rowsPerPage, state]);

    useEffect(() => {
        axios.get(API.getMiscAllFacility)
            .then(res => {
                console.log("Response", res);
                let { data } = res;
                setAllFacility(data?.data?.facilities || []);
            })
            .catch(err => {
                console.log("error", err);
            })
    }, []);

    const showAllPNUsers = () => {
        setIsLoading(true)
        axios.get(`${API.getAllPNUsers}`, {
            params: {
                search_text: props?.query || '',
                page_no: page,
                limit: rowsPerPage
            },
        })
            .then((res) => {
                const { data: { data: { User_details } } } = res
                setAllPNUserCredentials(User_details);
                setTotalCount(res?.data?.data?.totalCount)
                setIsLoading(false)
            })
            .catch((error) => {
                console.error("An error occurred:", error);
                setIsLoading(false)
            });
    }

    return (
        <>
            <Box sx={{ width: "100%", marginBottom: 2, boxShadow: '10px 24px 30px 10px #0000000F', border: "1px solid #D3DDEA", borderRadius: "8px", background: '#FFFFFF', background: '#FFFFFF', }}>
                {isLoading && <Box sx={style.spinner}>
                    <CircularProgress />
                </Box>}

                <TableContainer sx={{
                    height: "calc(100vh - 220px)", overflow: "auto", borderRadius: "8px",
                }}
                >
                    <Table>
                        <TableHead style={{position: "sticky",top: 0,zIndex: 1, boxShadow: '0px 15px 10px -10px lightgray',}}>
                            <TableRow style={{ height: "60px", background: '#F9F9F9' }}>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, fontSize: '16px', fontFamily: 'Poppins-Medium', color: '#63799B', left: column.l }}
                                    >
                                        <Box style={{ paddingLeft: column.l }}>
                                            {column.label}
                                        </Box>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allPNUserCredentials

                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.code}
                                            style={{ height: "60px" }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align} style={{ color: '#666666', fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
                                                        {column.id == "action" ? (
                                                            <Editimg onClick={() => onEdit(row)}
                                                                style={row?.user_status == "0" ? {
                                                                    cursor: "not-allowed",
                                                                } : { cursor: "pointer", }}
                                                            />
                                                        ) : column.id == "user_status" ?

                                                            <ToggleWithText isEdit={true} isEnabled={row.user_status === '1'} callBack={(e) => { updateStatus(e, row) }} />

                                                            : (
                                                                <Box style={{ marginLeft: column.l }}>
                                                                    {column.id == "facilityname" ?
                                                                        row?.facility_status == "1" ? <CustomWidthTooltip title={value} arrow><span style={{maxWidth: 420, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display: 'block', width: 'fit-content'}}>{value}</span></CustomWidthTooltip> : ""
                                                                        : value}
                                                                </Box>
                                                            )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            {isLoading ? null : allPNUserCredentials?.length == 0 && (<TableRow> <TableCell colSpan={6} style={{ textAlign: 'center' }}>No records found</TableCell> </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Outlet />
            </Box >

            <CommonPagination
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default UserTable;
