import * as React from 'react';
import { useState } from 'react';
import { Box, InputLabel, Link, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Paper, tableCellClasses, TableFooter } from '@mui/material';
import { RiDeleteBinLine } from "react-icons/ri";
import { Outlet, useNavigate } from 'react-router-dom';
import "../../../../../../assets/css/nla.css"
import store from '../../../../../../redux/store/store';
import _ from 'lodash';




const Referral = () => {

    const screenIndex = 1
    const [referrals, setReferrals] = useState([])

    React.useEffect(() => {
        let { referrals } = store?.getState()?.nlaReducer;
        setReferrals(referrals)
    }, [store?.getState()?.nlaReducer?.referrals])

    const columns = [
        { id: "region_name", label: "Region", minWidth: 100 },
        { id: "facilityname_from", label: "From", minWidth: 300 },
        { id: "facilityname_to", label: "To", minWidth: 300 },
        { id: "total_patient", label: "Patients", minWidth: 50, color: '#00133A' },
    ];

    return (
        <Paper elevation={0} sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1, padding: '24px' }} className='referrals'>

            
            {(referrals || []).map((referralItem) => {
                return (
                    <Box sx={{ paddingBottom: 5 }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            // paddingBottom: 5,
                            gap: 2
                        }}>
                            <InputLabel
                                style={{
                                    fontSize: 14,
                                    fontFamily: "Poppins-SemiBold",
                                    color: "#00133A",
                                    textTransform: 'capitalize'
                                }}
                            >
                                {referralItem?.journey_stage_name}
                            </InputLabel>
                            <span
                                style={{
                                    fontSize: 14,
                                    fontFamily: "Poppins-SemiBold",
                                    color: "#00133A",
                                }}
                            >
                                {referralItem?.final_total_patient}
                            </span>
                        </Box>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table"
                                sx={{
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none",
                                        padding: '4px 0px',
                                        margion: 0
                                    }
                                }}>
                                <TableHead>
                                    <TableRow >
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={column?.id === "total_patient" ? { minWidth: column.minWidth, color: '#8E8E8E', fontSize: 13, fontFamily: 'Poppins-Regular', textAlign: 'right' } : { minWidth: column.minWidth, color: '#8E8E8E', fontSize: 13, fontFamily: 'Poppins-Regular' }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(referralItem?.data || []).map((referralItemRow) => {

                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={referralItemRow.code}
                                            >
                                                {columns.map((column) => {
                                                    const value = referralItemRow[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}
                                                            style={{
                                                                minWidth: column.minWidth,
                                                                color: column.color || !value || '#838383',
                                                                fontSize: column.color ? 16 : 14,
                                                                fontFamily: column.color || !value ? 'Poppins-SemiBold' : 'Poppins-Medium',
                                                                display: column.color && 'flex',
                                                                justifyContent: 'flex-end',
                                                            }}>
                                                            {value || 'NA'}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                {/* <TableFooter>
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={referralItemRow.code}
                                    >
                                        {columns.map((column) => {
                                            const value = referralItemRow[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}
                                                    style={{
                                                        minWidth: column.minWidth,
                                                        color: column.color || !value || '#838383',
                                                        fontSize: column.color ? 16 : 14,
                                                        fontFamily: column.color || !value ? 'Poppins-SemiBold' : 'Poppins-Medium',
                                                        display: column.color && 'flex',
                                                        justifyContent: 'flex-end',
                                                    }}>
                                                    {value || 'NA'}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableFooter> */}
                            </Table>
                        </TableContainer>
                    </Box>
                )
            })}
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                // paddingBottom: 5,
                gap: 2,
                justifyContent: 'space-between'
            }}>
                <InputLabel
                    style={{
                        fontSize: 14,
                        fontFamily: "Poppins-SemiBold",
                        color: "#00133A",
                        textTransform: 'capitalize'
                    }}
                >
                    {"Total no. of Patients Referred"}
                </InputLabel>
                <span
                    style={{
                        fontSize: 14,
                        fontFamily: "Poppins-SemiBold",
                        color: "#00133A",
                    }}
                >
                    {referrals?.reduce((accumulator, currentValue) => accumulator + currentValue.final_total_patient, 0)}
                </span>
            </Box>
            {
               _.isEmpty(referrals)  && <span>No Data</span>
            }

            {/* <Box sx={{ paddingBottom: 5 }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    // paddingBottom: 5,
                    gap: 2
                }}>
                    <InputLabel
                        style={{
                            fontSize: 14,
                            fontFamily: "Poppins-SemiBold",
                            color: "#00133A",
                        }}
                    >
                        Investigation
                    </InputLabel>
                    <span
                        style={{
                            fontSize: 14,
                            fontFamily: "Poppins-SemiBold",
                            color: "#00133A",
                        }}
                    >
                        23
                    </span>
                </Box>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table"
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                                padding: '4px 0px',
                                margion: 0
                            }
                        }}>
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, color: '#8E8E8E', fontSize: 13, fontFamily: 'Poppins-Regular' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.code}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, color: column.color || '#838383', fontSize: 14, fontFamily: column.color ? 'Poppins-SemiBold' : 'Poppins-Medium' }}>
                                                    {value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{ paddingBottom: 5 }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    // paddingBottom: 5,
                    gap: 2
                }}>
                    <InputLabel
                        style={{
                            fontSize: 14,
                            fontFamily: "Poppins-SemiBold",
                            color: "#00133A",
                        }}
                    >
                        Treatment Initiation
                    </InputLabel>
                    <span
                        style={{
                            fontSize: 14,
                            fontFamily: "Poppins-SemiBold",
                            color: "#00133A",
                        }}
                    >
                        23
                    </span>
                </Box>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table"
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                                padding: '4px 0px',
                                margion: 0
                            }
                        }}>
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, color: '#8E8E8E', fontSize: 13, fontFamily: 'Poppins-Regular' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.code}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, color: column.color || '#838383', fontSize: 14, fontFamily: column.color ? 'Poppins-SemiBold' : 'Poppins-Medium' }}>
                                                    {value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box> */}
        </Paper >
    )
}
export default Referral