import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AiOutlineMonitor } from 'react-icons/ai'
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { AiFillPieChart } from 'react-icons/ai'
import { AiFillSetting } from 'react-icons/ai'
import '../../assets/css/home.css'
import Glyph from '../../assets/images/Glyph.png'
import Layer from '../../assets/images/Layer_1.png'
import Pie from '../../assets/images/pie-chart.png'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import BackgroundBg from '../../assets/images/logo-bg.png'
import Back from '../../assets/images/back.png'
import { InputLabel } from '@mui/material';
import store from '../../redux/store/store';

const Roche = () => {
    let { loginInfo } = store?.getState()?.auth;
    const navigate = useNavigate();
    const navigateCountry = () => {
        navigate('/roche');
    };
    const navigateStatistic = () => {
        navigate('/reports/patient-journey');
    };
    return (
        <>
            <div style={{ backgroundImage: `url(${BackgroundBg})`, width: '100%', height: '549px' }}>
                <div className='outer'>
                    <div className='inner-for'>

                        <p className="login-par-mobile">Welcome Roche !</p>
                        <p className="login-innerpar2">Please click on the box below to view Statistics and Reports</p>

                        <div className='d-flex flex-row justify-content-center icons-row'>

                            <div className='d-flex flex-column col-2 inner-col ' onClick={navigateStatistic}>
                                <img className='nla-img' src={Pie} alt="" />

                                <p className='setting-par-roche'>Statistics & Reports</p>
                            </div>
                        </div>
                        <div>
                        </div>
                        <div className='d-flex flex-row justify-content-center forgot-row country' onClick={navigateCountry} style={{position: 'relative'}}>
                            <div className='d-flex flex-column'>
                                {/* <AiOutlineArrowLeft color="#00ADEE" className='arr-let' /> */}
                                <img className='back-img' src={Back} alt="" />
                            </div>
                            <div className='d-flex flex-column'>
                                <p className='back-login'>Back to Country List</p>
                            </div>
                            <InputLabel
                                style={{
                                color: '#000',
                                marginTop: 80,
                                fontSize: 25,
                                // cursor: 'pointer',
                                // textDecoration: 'underline',
                                // fontFamily: 'Poppins-Bold'
                                fontSize: 13,
                                position: 'absolute',
                                left: 20, 
                                bottom: 20
                                }}
                                // onClick={onExit}
                            >
                                {`${loginInfo?.app_version}`}
                            </InputLabel>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Roche