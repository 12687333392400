import { Box, Button, Typography, Modal, TextField, Label, FormLabel, Select, InputLabel, MenuItem, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import {API } from "../../../constants/constant";
import axios from '../../../http/axios';
import _ from "lodash";

const style = {
    container: {
        top: '50%',
        left: '50%',
        alignItem: 'center',
        transform: 'translate(-50%, -50%)',
        width: "39%",
        // height: '54vh',
        maxHeight: "510px",
        height: "90%",
        bgcolor: '#ffffff',
        boxShadow: 15,
        borderRadius: 10,
        position: 'fixed',
    },
    Additem: {
        display: 'flex',
        flexDirection: 'column',
        gap: 30,
        marginTop:30,
        margin: '30px 30px 0px 30px',
        height: "86%",
        overflow: "auto"
    },
    close: {
        position: 'absolute',
        cursor: 'pointer',
        top: -20,
        right: -15,
        width: 55,
        height: 55,
        backgroundColor: '#fda945',
        borderColor: '#fff',
        borderRadius: 30,
        border: '2px solid #fff',
        textAlign: 'center'
    },
    button: {
        height: 50,
        marginBottom: 4,
        width: '-webkit-fill-available',
        marginTop: 14,
        borderRadius: 30,
        background: "#546FE6",
        textTransform: 'none',
        fontSize: 20,
        fontFamily: 'Poppins-SemiBold'
    },
    dropdownTitle: {
        fontSize: 14,
        fontFamily: "Poppins-Regular",
        color: "#464A53",
    },
    select: {
        display: 'flex',
        minWidth: 170,
        height: 46.48,
        background: "#ffffff",
        borderRadius: 4,
        border: '0px solid #f4f6fa',
    },

};

const AddEditUserCredentials = (props) => {
    const [open, setOpen] = useState(true);
    const { state } = useLocation();
    const navigate = useNavigate();;
    const handleClose = () => navigate(-1);
    const [selectedItem, setSelectedItem] = useState({ facility: state?.rowItem?.facilitycode, password: state?.rowItem?.password,email:state?.rowItem?.email })
    const [errors, setErrors] = useState({
        password: false,
    });
    const [isLoading, setIsLoading] = useState(false);

    const onHandleChange = (event, key) => {
        const {
            target: { value },
        } = event;
        let data = {...selectedItem}
        data[key] = value
        setSelectedItem(data)
    };

    const updatePNPW = () => {
        const data = {
            user_id:state?.rowItem?.user_id,
            password: selectedItem?.password, 
            facilitycode: selectedItem?.facility,
        };

        var config = {
            method: 'put',
            url: API.updatePNPW,
            data: data,
            headers: { "Content-Type": "application/json" }
        };
        let errorsItem = {};
        if (!selectedItem?.password?.trim()) {
            errorsItem['password'] = true;
        }
        if (!_.isEmpty(errorsItem)) {
            setErrors({ ...errors, ...errorsItem });
            return;
        }
        setIsLoading(true)

        axios.request(config)
        .then((res) => {
            console.log("Data updated successfully:", res.data);
            setIsLoading(false)
            navigate("/user-credential");
        })
        .catch((error) => {
            setIsLoading(false)
            console.error("An error occurred:", error);
        });
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style.container}>
                    <Box style={style.close}>
                        <MdOutlineClose size={30} style={{ color: "#fff", marginTop: 10 }} onClick={handleClose}></MdOutlineClose>
                    </Box>
                    <Box sx={{ padding: '50px 50px 60px 50px', height:"100%", overflow:'hidden' }}>
                        <Typography id="modal-modal-description" sx={{ mt: 1, color: '#546FE6', fontSize: 28, fontFamily: 'Montserrat-Bold' }} >
                            {state?.title}
                        </Typography>

                        <Box style={style.Additem}>
                            <Box>
                                <FormLabel id="facilityname-addfacility" sx={{ color: '#ACB1B6', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Email </FormLabel>
                                <TextField id="facility-input" fullWidth variant="standard" 
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: '#464A53',
                                            borderBottom:  "1px dashed #00000029",
                                            fontSize: 16,
                                            fontFamily: 'Poppins-Regular',
                                        },
                                    }}  value={selectedItem?.email} 
                                onChange={(e) => onHandleChange(e, "email")}
                                disabled={true} />
                            </Box>
                            <Box>
                                <FormLabel id="facilityname-addfacility" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Password <span style={{ fontFamily: "Poppins-Regular", fontSize: 16, color: "#EE2D2D", paddingLeft: 1 }}>*</span> </FormLabel>
                                <TextField id="facility-input" fullWidth variant="standard" 
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: '#464A53',
                                            borderBottom: errors?.password ? '1px solid red' : "1px solid grey",
                                            fontSize: 16,
                                            fontFamily: 'Poppins-Regular',
                                        },
                                    }} onFocus={() => setErrors({ ...errors, password: false })} value={selectedItem?.password} 
                                onChange={(e) => onHandleChange(e, "password")} />
                            </Box>
                            <Box>
                                <FormLabel id="region-addfacility" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Facility <span style={{ fontFamily: "Poppins-Regular", fontSize: 16, color: "#EE2D2D", paddingLeft: 1 }}>*</span></FormLabel>
                                    <Select
                                    value={selectedItem?.facility}
                                    variant="standard"
                                    onChange={(e) => onHandleChange(e, "facility")}
                                    style={style.select}
                                >
                                    {(state?.allFacility || []).map((item) => {
                                        return <MenuItem value={item.facilitycode}>{item.facilityname}</MenuItem>;
                                    })}
                                </Select>
                            </Box>
                            <Button
                                variant="contained"
                                style={style.button}
                                onClick={updatePNPW}
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress color="inherit"/> : "Save"}
                            </Button>

                        </Box>
                    </Box>
                </Box>
            </Modal >
        </>
    );
};
export default AddEditUserCredentials