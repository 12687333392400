import * as React from "react";
import {
    IconButton,
    InputLabel,
    Paper,
    Tooltip
} from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/material";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import Divider from '@mui/material/Divider';
import store from "../../../../../redux/store/store";
import { fills, renderPieData } from "../../../../../constants/constant";
import { PieChart, pieArcLabelClasses } from '@mui/x-charts';
import { BsFillSquareFill } from "react-icons/bs";
import Helper from '../../../../../assets/images/helper.svg';

const PatinetActionPlanning = (props) => {

    const screenIndex = 3
    const [process, setProcess] = useState([])
    const [patientData, setPatientData] = useState(undefined)
    const [noDataPie, setNoDataPie] = useState(false)
    let { notificationConfig } = store?.getState()?.nlaReducer;
    React.useEffect(() => {
        let { process } = store?.getState()?.nlaReducer;
        process = (process || [])?.find(e => e.journey_step_seq == screenIndex) || [];
        setProcess(process?.journey_data || [])
        setNoDataPie(process?.no_data)
        setPatientData(_setPatientData(process?.patient_data))
    }, [store?.getState()?.nlaReducer?.process])

    const checkInfinity = (value) => {
        const mathRetult = parseFloat(value)
        return Number.isFinite(mathRetult) ? Math.abs(mathRetult).toFixed(1) : "0"
    }

    const _setPatientData = (item) => {
        return {
            ...item,
            patients: { //RV more
                value: item?.investigation_patient || 0,
                percentage: item?.investigation_patient_prev ? checkInfinity(((item?.investigation_patient - item?.investigation_patient_prev) / item?.investigation_patient_prev) * 100) : 0, //RV
                isUp: item?.investigation_patient <= item?.investigation_patient_prev, //RV
                checkValue: item?.investigation_patient_prev ? ((item?.investigation_patient - item?.investigation_patient_prev) / item?.investigation_patient_prev) * 100 : 0
            },
            actionPlanning: {
                value: item?.action_planning_patient || 0,
            },
            staging: {
                value: item?.staging_patient || 0,
                data: item?.staging.map((item, i) => {
                    return ({
                        percentage: item?.staging_patient,
                        value: item?.staging_patient,
                        name: item?.staging_name,
                        _id: i.toString(),
                        fill: item?.staging_color,
                        staging_patient_count: item?.staging_patient_count
                    })
                }),
                no_data: item?.no_data

            },
        }
    }

    return (
        <>
            <Paper
                elevation={0}
                sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: "10px" }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 20px"
                    }}
                >
                    <InputLabel
                        style={{
                            fontSize: 14,
                            fontFamily: "Poppins-Medium",
                            color: "#838383",
                            paddingBottom: 5,
                            marginTop: 0,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {"Appointments"}
                        <Tooltip title={notificationConfig?.PROCESS_APPOINTMENT} arrow placement="right">
                            <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                        </Tooltip>
                    </InputLabel>
                    <Box
                        style={{
                            // display: "flex",
                            flexDirection: "row",
                            // alignItems: "center",
                            justifyContent: 'space-between',
                            // padding: "20px 0",

                            paddingTop: 0
                        }}
                    >
                        <Box
                            style={{
                                display: "flex", flexDirection: "column", alignItems: 'start', height: props?.export ? 2000 : 150,
                                overflowY: 'auto',
                            }}
                        >
                            {(process || []).map((processItem, i) => {
                                return (
                                    processItem?.data.map((milestone, key) => {
                                        return (
                                            <Box sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: 'space-between', width: '100%', paddingRight: '5px' }} key={key}>
                                                <span
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins-SemiBold",
                                                        color: "#00133A",
                                                        width: 30,
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    {milestone.total_patient}
                                                </span>
                                                <div
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins-Medium",
                                                        color: "#838383",
                                                        display: 'flex',
                                                        gap: 2
                                                    }}
                                                >
                                                    <BsFillSquareFill color={milestone.step_color} style={{ marginRight: 6, width: 10, marginTop: 4 }} />
                                                    <span style={{ width: 'fit-content' }}>{milestone.appTitle}</span>
                                                </div>
                                            </Box>
                                        )
                                    })

                                )
                            })}


                        </Box>
                        {!noDataPie ?
                            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginBottom: 30, marginTop: 20 }}>
                                <Box style={{ display: "flex", alignItems: 'center', width: 250, height: 250 }}>
                                    <PieChart
                                        series={[
                                            {
                                                innerRadius: 0,
                                                outerRadius: 80,
                                                data: renderPieData(process)?.[0],
                                                arcLabel: (item) => `${item.value ? item.value : ''}`
                                            },
                                            {
                                                innerRadius: 81,
                                                outerRadius: 125,
                                                data: renderPieData(process)?.[1],
                                                arcLabel: (item) => `${item.value ? item.value : ''}`
                                            },
                                        ]}
                                        sx={{
                                            [`& .${pieArcLabelClasses.root}`]: {
                                                fill: 'white',
                                                fontWeight: 'bold',
                                                fontSize: 10,
                                                width: '30px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            },
                                        }}
                                        width={250}
                                        height={250}
                                        slotProps={{
                                            legend: { hidden: true },
                                        }}
                                        colors={process.map(item => item?.step_color)}
                                        margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
                                    />
                                </Box>
                            </div> :
                            <div style={{ marginTop: 20, marginBottom: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', }}><div style={{ width: 250, height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: '1px solid #b9b1b1', background: '#f44f4f', color: '#fff' }}>No Data</div></div>}
                    </Box>
                    <Divider variant="middle" />
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: 'space-between',
                                padding: "10px 0px",
                            }}>
                            <InputLabel
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                }}
                            >
                                {"PATIENTS"}
                                <Tooltip title={notificationConfig?.INVESTIGATION_PATIENT_COUNT} arrow placement="right">
                                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                                </Tooltip>
                            </InputLabel>


                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    padding: "10px 0",
                                }}
                            >
                                <h3
                                    style={{
                                        fontSize: 22,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                        paddingRight: 10
                                    }}
                                >
                                    {patientData?.patients.value}
                                </h3>
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontFamily: "Poppins-Medium",
                                        paddingRight: 5,
                                        color: "#6d706f",
                                    }}
                                >
                                    {patientData?.patients.percentage + '%'}
                                </span>
                                {patientData?.patients.checkValue >= 0 ? 
                                <BiSolidUpArrow color={"#6d706f"} size={8} /> :
                                <BiSolidDownArrow color={"#6d706f"} size={8} />}
                            </Box>
                        </Box>
                        <Divider
                            style={{
                                height: 1,
                                width: 40,
                                transform: "rotate(90deg)",
                                background: "gray",
                            }}
                        />
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: 'space-between',
                                padding: "10px 0",
                            }}>
                            <InputLabel
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                }}
                            >
                                {"STAGING"}
                                <Tooltip title={notificationConfig?.STAGING_COUNT} arrow placement="right">
                                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                                </Tooltip>
                            </InputLabel>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    padding: "10px 0",
                                }}
                            >
                                <h3
                                    style={{
                                        fontSize: 25,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                        paddingRight: 10
                                    }}
                                >
                                    {patientData?.staging.value}
                                </h3>
                            </Box>
                        </Box>
                        {/* <Divider
                            style={{
                                height: 1,
                                width: 40,
                                transform: "rotate(90deg)",
                                background: "gray",
                            }}
                        />
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: 'space-between',
                                padding: "10px 0",
                            }}>
                            <InputLabel
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                }}
                            >
                                {"ACTION PLANNING"}
                            </InputLabel>
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    // alignItems: "center",
                                    justifyContent: 'space-between',
                                    padding: "10px 0",
                                }}>
                                <h3
                                    style={{
                                        fontSize: 25,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                        paddingRight: 10
                                    }}
                                >
                                    {patientData?.actionPlanning.value}
                                </h3>
                            </Box>
                        </Box> */}
                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignItems: "center",
                                padding: '0px 10px',
                                marginTop: "0px",
                                marginBottom: '20px',
                                alignItems: 'center',
                                width: '100%'
                            }}
                        >
                            {!patientData?.staging?.no_data ?
                                <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                    <Box style={{ display: "flex", alignItems: 'center', width: 100, height: 100, marginRight: 20, marginTop: 20 }}>
                                        <PieChart
                                            series={[
                                                {
                                                    innerRadius: 0,
                                                    data: patientData?.staging?.data?.length ? patientData?.staging?.data?.map(item => {
                                                        return { ...item, value: parseInt(item?.staging_patient_count), label: item?.name }
                                                    }) : [],
                                                    arcLabel: (item) => `${item.staging_patient_count ? item.staging_patient_count : ''}`,
                                                }]}
                                            sx={{
                                                [`& .${pieArcLabelClasses.root}`]: {
                                                    fill: 'white',
                                                    fontWeight: 'bold',
                                                    fontSize: 10,
                                                    width: '30px',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                },
                                            }}
                                            width={100}
                                            height={100}
                                            margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
                                            slotProps={{
                                                legend: { hidden: true },
                                            }}
                                            colors={patientData?.staging?.data?.map(item => item?.fill)}
                                        />
                                    </Box>
                                </div> :
                                <div style={{ height: 100, marginLeft: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 10 }}><div style={{ width: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: '1px solid #b9b1b1', fontSize: 12, background: '#f44f4f', color: '#fff' }}>No Data</div></div>
                            }
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    // flexWrap: "wrap",
                                    width: 'calc(100% - 120px)'
                                }}>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: "0px 0px 15px 0px"}}>
                                        <span style={{fontSize: 12, fontFamily: "Poppins-SemiBold",
                                                    color: "#00133A", minWidth: 40}}></span>
                                        
                                            <span
                                                style={{
                                                    fontSize: 12,
                                                    fontFamily: "Poppins-SemiBold",
                                                    color: "#00133A",
                                                    // width: 40
                                                }}>
                                                Percentage
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: 12,
                                                    fontFamily: "Poppins-SemiBold",
                                                    color: "#00133A",
                                                    width: 60,
                                                    textAlign: 'right'
                                                }}>
                                                Patients
                                            </span>
                                    </div>
                                {patientData?.staging?.data?.map((item, i) => {
                                    return (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                // flexWrap: "wrap",
                                                justifyContent: 'space-between'
                                            }}>
                                            <span style={{
                                                fontSize: 14,
                                                fontFamily: "Poppins-Medium",
                                                color: item.fill,
                                                // paddingLeft: 8,
                                                // width: 147
                                            }}>{item.name}</span>
                                            <span style={{
                                                fontSize: 14,
                                                fontFamily: "Poppins-Medium",
                                                color: "#838383",
                                                paddingRight: 8,
                                            }}>{(item.percentage || 0) + "%"}</span>
                                            <span style={{
                                                fontSize: 14,
                                                fontFamily: "Poppins-Medium",
                                                color: "#838383",
                                                paddingRight: 8,
                                            }}>{parseInt(item.staging_patient_count || 0)}</span>

                                        </Box>

                                    )
                                })}
                            </Box>


                        </Box>
                        
                    </Box>
                    <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: 'space-between',
                                padding: "10px 0",
                            }}>
                            <InputLabel
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                }}
                            >
                                {"DISCONTINUED PATIENTS"}
                                <Tooltip title={notificationConfig?.DISCONTINUE_PATIENT} arrow placement="right">
                                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                                </Tooltip>
                            </InputLabel>
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    // alignItems: "center",
                                    justifyContent: 'space-between',
                                    padding: "10px 0",
                                }}>
                                <h3
                                    style={{
                                        fontSize: 25,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                        paddingRight: 10
                                    }}
                                >
                                    {patientData?.discontinue_patient}
                                </h3>
                            </Box>
                        </Box>
                </Box>
                <Box>
                    <div style={{textAlign: 'center', fontSize: 14, fontWeight: '600', marginBottom: 10}}>*Old data prior to change of 'Appointment titles'</div>
                </Box>
                
            </Paper >
        </>
    )
}
export default PatinetActionPlanning