import { Box, InputLabel } from "@mui/material"
import SuspectedScreening from "./suspectedscreening"
import DiagnosedPatients from "./diagnosedpatients"
import PatinetActionPlanning from "./patientactionplanning"
import TreatmentInitiations from "./treatmentinitiations"
import InformedConsent from "./informedconsent"
import "../../../../../assets/css/patientJourney.css";

const ProcessStatistics = (props) => {

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: '30px',
            flexWrap: "wrap",
            // alignContent: 'center'
        }}>
            <InputLabel
                style={{
                    fontSize: 24,
                    fontFamily: "Poppins-Medium",
                    color: "#554886",
                    marginLeft: '8px'
                }}
            >
                Process
            </InputLabel>
            <Box
                className="section-container"
                sx={{
                    "& > :not(style)": {
                        m: 1,
                        flex: 1,
                        minWidth: 360,
                        // height: 485,
                    },
                }}>
                {/*SUSPECTED SCREENINGS*/}
                < SuspectedScreening export={props.export} />
                {/*DIAGNOSED PATIENTS*/}
                < DiagnosedPatients export={props.export}/>
                {/*PATIENTS ACTION PLANNING*/}
                < PatinetActionPlanning export={props.export} />
                {/*INFORMED CONSENT*/}
                < InformedConsent export={props.export} />
                {/*TREATMENT INITIATIONS*/}
                < TreatmentInitiations export={props.export} />
            </Box >
        </Box>
    )
}
export default ProcessStatistics