import * as React from "react";
import { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { styled } from "@mui/material/styles";
import {
  Grid,
  InputLabel,
  Paper,
  ThemeProvider,
  Typography,
  createTheme,
  LinearProgress,
  Tooltip,
  Button,
  MenuItem,
  Select,
  Stack,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { Box, Link } from "@mui/material";
import { FiMonitor } from "react-icons/fi";
import { BiSolidUpArrow } from "react-icons/bi";
import { BsFillSquareFill } from "react-icons/bs";
import Divider from "@mui/material/Divider";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SuspectedScreening from "./component/process/suspectedscreening";
import DiagnosedPatients from "./component/process/diagnosedpatients";
import InformedConsent from "./component/process/informedconsent";
import PatinetActionPlanning from "./component/process/patientactionplanning";
import TreatmentInitiations from "./component/process/treatmentinitiations";
import ProcessStatistics from "./component/process";
import JourneyPhase from "./component/journeyphase";
import RiskFactors from "./component/othersections/riskfactors";
import StatisticsOtherSection from "./component/othersections";
import { TopNavigation } from "../../../common/control/topnavigation";
import ExportI from "../../../assets/images/navigator/export.png";
import NationalCancerImg from "../../../assets/images/g36-preview.png";
import EswatiniImg from "../../../assets/images/g48-preview.png";
import Barriers from "./component/barriers/index";
import Completion from "./component/completion/index";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Helper from '../../../assets/images/helper.svg';
import {
  MOCKUP_CANCERTYPE,
  MOCKUP_DURATION,
  MOCKUP_MONTH,
  MOCKUP_YEAR,
  MOCKUP_REGION,
  API,
  EXPORT_KEY,
  MOCKUP_MONTH_QAURTERLY,
  MOCKUP_MONTH_HALFYEARLY,
  MOCKUP_MONTH_YEARLY,
} from "../../../constants/constant";
import { useRef } from "react";
import axios from "../../../http/axios";
import _ from "lodash";
import { HttpStatusCode } from "axios";
import CommonDropDown from "../../../common/control/commondropdown";
import store from "../../../redux/store/store";
import {
  getAllInsightsDays,
  getAppUsage,
  getBarries,
  getBarriesFigure,
  getCompletionRate,
  getDeath,
  getJournetPhase,
  getNotificationConfig,
  getProcess,
  getProgramOverview,
  getReferrals,
  getRiskFactors,
  getTravel,
} from "../../../redux/actions/nlaactions";
import { connect, useSelector } from "react-redux";
import { useEffect } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { CustomLoader } from "../../../common/control/customloader";

const RADIAN = Math.PI / 180;

const Style = {
  dropdownTitle: {
    fontSize: 14,
    fontFamily: "Poppins-Regular",
    color: "#464A53",
  },
  select: {
    display: "flex",
    minWidth: 170,
    height: 46.48,
    background: "#ffffff",
    borderRadius: 4,
    border: "1px solid #f4f6fa",
  },
  pdfHeaderContent: {
    padding: "25px 17px",
    background: "#fff",
  },
  content: {
    color: "#554886",
    fontSize: 35,
    fontFamily: "Poppins-Regular",
    textAlign: "center",
    marginBottom: 10,
  },
  cancerTypeText: {
    color: "#959595",
    fontSize: 12,
    fontFamily: "Poppins-Regular",
  },
  screenSus: {
    color: "#959595",
    fontSize: 12,
    fontFamily: "Poppins-SemiBold",
  },
  cancerText: {
    color: "#554886",
    fontSize: 20,
    fontFamily: "Poppins-Regular",
  },
  verticalLine: {
    height: 40,
    border: "1px solid #959595",
  },
  overviewText: {
    color: "#554886",
    fontSize: 25,
    fontFamily: "Poppins-Regular",
  },
  suspicionCon: {
    border: "1px solid #AAAAAA",
    borderRadius: 8,
    padding: "5px 14px",
  },
  dateCreatedText: {
    color: "#939393",
    fontSize: 16,
    fontFamily: "Poppins-Regular",
    position: "absolute",
    right: 50,
    top: 50,
  },
  pdfContent: {
    position: "absolute",
    right: 1000000,
    width: "100%",
    top: 0,
    height: "100vh",
    overflow: "scroll",
  },
  spinner: {
    display: "flex",
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    alignItems: "center",
    justifyContent: "center",
  },
};

const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h2",
          h2: "h2",
          h3: "12",
          h4: "h2",
          h5: "h2",
          h6: "h6",
        },
        fontFamily: "Poppins-Medium",
        fontWeight: 500,
        color: "#4B4B4B",
      },
    },
  },
});

const AllInsights = ({ allInsightsCategory }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBarrier, setIsLoadingBarrier] = useState(true);
  const [category, setCategory] = React.useState();
  const [pdfLogoData, setPdfLogoData] = React.useState(null);
  const { country_id } = useSelector(state => state.auth)
  const { allInsightsDays } = useSelector(state => state.nlaReducer)
  const [onLoadedApi, setonLoadApi] = useState(true)
  let { regions_list, cancerTypes_list } = store?.getState()?.auth;
  useEffect(() => {
    const allInsightsCategoryToObject = {};
    allInsightsCategory?.forEach((item) => {
      allInsightsCategoryToObject[item.name] = item;
    });
    setCategory(allInsightsCategoryToObject);
  }, [allInsightsCategory]);

  const [regions, setRegions] = useState({
    list: [],
    selectedItems: [],
    name: '',
    error: false,
  });

  const [cancerTypes, setCancerTypes] = useState({
      list: [],
      selectedItems: [],
      name: '',
      error: false,
  });

  const [duration, setDuration] = useState({
      list: MOCKUP_DURATION,
      selectedItems: [],
      name: "",
      error: false,
  });
  const [month, setMonth] = useState({
      list: MOCKUP_MONTH,
      selectedItems: [],
      name: "",
      error: false,
  });
  const [year, setYear] = useState({
      list: MOCKUP_YEAR,
      selectedItems: [],
      name: "",
      error: false,
  });

  const pdfRef = useRef();

  const [queryParams, setQueryParams] = useState({});



  useEffect(() => {
    // Parse query parameters from the URL
    const searchParams = new URLSearchParams(window.location.search);

    // Initialize an empty object to store query parameters
    const params = {};

    // Iterate over each query parameter and add it to the params object
    for (const [key, value] of searchParams) {
      params[key] = value;
    }

    // Update state with the parsed query parameters
    setQueryParams(params);
  }, []);

  useEffect(() => {
    let { loginInfo } = store?.getState()?.auth;
    if(loginInfo?.user_role === "ADMIN") {
      var config = {
        method: 'get',
        url: `${API.getRochePDFLogo}?country_id=${country_id}`
      };
      axios.request(config)
        .then(function (response) {
          console.log(response,"rerererererere")
          setPdfLogoData(response?.data?.data)
        
        })
        .catch(function (error) {
          console.log(error,"eerererererererer");
        });
    }
  },[])
  
  React.useEffect(() => {
    setIsLoading(true);
    // if (!_.isEmpty(cancerTypes.selectedItems)) fetchRocheAllInsightReportsAndBarriers();
    if(regions?.name && cancerTypes?.name && duration?.name && month?.name && year?.name && onLoadedApi) {
      fetchRocheAllInsightReportsAndBarriers()
    }
    else setIsLoading(false);
  }, [regions, cancerTypes, duration, month, year, onLoadedApi]);

  const renderMonthList = (type) => {
    if(type === "2") {
      return MOCKUP_MONTH_QAURTERLY
    }
    if(type === "3") {
      return MOCKUP_MONTH_HALFYEARLY
    }
    if(type === "4") {
      return MOCKUP_MONTH_YEARLY
    }
    return MOCKUP_MONTH
  }

  const setMonthData = () => {
    if (duration) {
        switch (duration.name) {
          case duration.list[0].name:
              setMonth({
                ...month,
                list: MOCKUP_MONTH,
                // selectedItems: [MOCKUP_MONTH.find(e => e._id == new Date().getMonth() + 1).value],
                // name: MOCKUP_MONTH.find(e => e._id == new Date().getMonth() + 1).name,
                // error: false,
              })
              break
          case duration.list[1].name:
              setMonth({
                ...month,
                list: MOCKUP_MONTH_QAURTERLY,
                // selectedItems: [1],
                // name: MOCKUP_MONTH_QAURTERLY[0].name,
                // error: false,
              })
              break
          case duration.list[2].name:
              setMonth({
                ...month,
                list: MOCKUP_MONTH_HALFYEARLY,
                // selectedItems: [1],
                // name: MOCKUP_MONTH_HALFYEARLY[0].name,
                // error: false,
              })
              break
          case duration.list[3].name:
              setMonth({
                ...month,
                list: MOCKUP_MONTH_YEARLY,
                // selectedItems: [1],
                // name: MOCKUP_MONTH_YEARLY[0].name,
                // error: false,
              })
              break
        }
    }
}

  useEffect(() => {
    if(window.location.search) {
      if(regions_list?.length && cancerTypes_list?.length) {
        setRegions({
          list: regions_list,
          selectedItems: [queryParams?.region],
          name: regions_list?.find(itm => itm.value === queryParams?.region)?.name,
          error: false,
        })
        setCancerTypes({
            list: cancerTypes_list,
            selectedItems: [queryParams?.cancerTypes],
            name: cancerTypes_list?.find(itm => itm.value === queryParams?.cancerTypes)?.name,
            error: false,
        });
        setMonth({
          list: MOCKUP_MONTH,
          selectedItems: [queryParams?.month],
          name: renderMonthList(queryParams?.duration).find(itm => itm.value === queryParams?.month)?.name,
          error: false,
        })
        setDuration({
          list: MOCKUP_DURATION,
          selectedItems: [queryParams?.duration],
          name: MOCKUP_DURATION.find(itm => itm.value === queryParams?.duration)?.name,
          error: false,
        });
        setYear({
          list: MOCKUP_YEAR,
          selectedItems: [queryParams?.year],
          name: MOCKUP_YEAR.find(itm => itm.value === queryParams?.year)?.name,
          error: false,
        });
      }
    } else {
      if(regions_list?.length && cancerTypes_list?.length) {
        setRegions({
          list: regions_list,
          selectedItems: [0],
          name: regions_list?.[0]?.name,
          error: false,
        })
        setCancerTypes({
            list: cancerTypes_list,
            selectedItems: [localStorage.getItem("selectedCancerType") ? localStorage.getItem("selectedCancerType") : 1001],
            name: cancerTypes_list?.[0]?.name,
            error: false,
        });
        setMonth({
          list: MOCKUP_MONTH,
          selectedItems: [MOCKUP_MONTH.find(e => e._id == new Date().getMonth() + 1).value],
          name: MOCKUP_MONTH.find(e => e._id == new Date().getMonth() + 1).name,
          error: false,
        })
        setDuration({
          list: MOCKUP_DURATION,
          selectedItems: [1],
          name: MOCKUP_DURATION[0].name,
          error: false,
        });
        setYear({
          list: MOCKUP_YEAR,
          selectedItems: [MOCKUP_YEAR.find(e => e.name == new Date().getFullYear())?.value],
          name: MOCKUP_YEAR.find(e => e.name == new Date().getFullYear())?.name,
          error: false,
        });
      }
    }
    
  },[queryParams, regions_list, cancerTypes_list])

  useEffect(() => {
    if(duration) {
      setMonthData()
    }
  },[duration])


  const params = {
    country_id,
    region: regions?.selectedItems?.[0],
    cancer_type: cancerTypes?.selectedItems?.[0],
    duration: duration?.name,
    duration_month: month?.selectedItems?.[0],
    year: year?.name,
  };
  
  const handleRegionChange = (event, { props }) => {
    const {
        target: { value },
    } = event;
    setRegions({
        ...regions,
        selectedItems: typeof value === "string" ? value.split(",") : value,
        name: props.children
    });
  };
  const handleCancerTypeChange = (event, { props }) => {
      const {
          target: { value },
      } = event;
      setCancerTypes({
          ...cancerTypes,
          selectedItems: typeof value === "string" ? value.split(",") : value,
          name: props.children
      });
  };
  const handleDurationChange = (event, { props }) => {
      const {
          target: { value },
      } = event;
      setDuration({
          ...duration,
          selectedItems: typeof value === "string" ? value.split(",") : value,
          name: props.children
      });
      setMonth({
          ...month,
          selectedItems: [1]
      });
      
  };
  const handleMonthChange = (event, { props }) => {
      const {
          target: { value },
      } = event;
      setMonth({
          ...month,
          selectedItems: typeof value === "string" ? value.split(",") : value,
          name: props.children
      });
  };
  const handleYearChange = (event, { props }) => {
      const {
          target: { value },
      } = event;
      setYear({
          ...year,
          selectedItems: typeof value === "string" ? value.split(",") : value,
          name: props.children
      });
  };

  const fetchRocheAllInsightReportsAndBarriers= async () => {
    try {
      const promiseArray = [axios.get(API.allInsightsReport, { params }), axios.get(API.allInsightsBarrier, { params })]
      const response = await Promise.all(promiseArray);
      
      response?.forEach((item, index) => {
        let { data: { data } } = item
        if (!_.isEmpty(data) && index == 0)
          _mapAllInsightsReport(data);
        else if (!_.isEmpty(data) && index == 1)
          _mapAllInsightsBarrier(data);
      })
      setIsLoading(false);
      setonLoadApi(false)
    }
    catch (error) {
      setIsLoading(false);
      alert(error?.message);
      console.log(error);
    }

  };

  const _onTopNavButtonCallBack = () => {
    debugger
    const selectedCancerType = cancerTypes.list.find(
      (e) => e.value == cancerTypes.selectedItems[0]
    );
    const selectedMonth = month.list.find(
      (e) => e.value == month.selectedItems[0]
    );
    const selectedYear = year.list.find(
      (e) => e.value == year.selectedItems[0]
    );
    const selectedDuration = duration.list.find(
      (e) => e.value == duration.selectedItems[0]
    );
    const selectedRegions = regions.list.find(
      (e) => e.value == regions.selectedItems[0]
    );
    navigate("export-insights", {
      state: {
        fileName: `RedPath - Statistics Report -
    ${selectedCancerType?.name} - ${selectedMonth?.name} ${selectedYear?.name}`,
        selectedCancerType,
        selectedMonth,
        selectedYear,
        selectedDuration,
        selectedRegions,
        country_id
      },
    });
  };

  const dateTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ap = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let mergeTime = hours + ":" + minutes + " " + ap;
    return mergeTime;
  };

  const getMonthName = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[date.getMonth()];
  };

  const renderPdfContent = () => {
    let { loginInfo } = store?.getState()?.auth;
    let { programOverview, barriesFigure, referrals, notificationConfig } = store?.getState()?.nlaReducer;
    console.log(loginInfo,"loginInfo")
    return (
      <div id="pdfReport" style={Style.pdfContent}>
        <Stack
          display={"flex"}
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems="center"
          style={Style.pdfHeaderContent}
        >
          <div>
            <img src={loginInfo?.user_role === "ADMIN" ? pdfLogoData?.country_logo : loginInfo?.country_logo} style={{maxWidth: 184}} />
          </div>
          <div>
            <div>
              {/* <Stack style={Style.content}>Statistics Report - {getMonthName(new Date())} {new Date().getFullYear()}</Stack> */}
              <Stack style={Style.content}>
                Statistics Report - {month?.name} {year?.name}
              </Stack>
              <Stack
                display="flex"
                alignItems={"center"}
                flexDirection="row"
                gap="50px"
                minHeight={"40px"}
              >
                <Stack
                  display="flex"
                  alignItems={"center"}
                  flexDirection="row"
                  gap="25px"
                >
                  <div style={Style.cancerTypeText}>Cancer Type</div>
                  <div style={Style.cancerText}>{cancerTypes?.name}</div>
                </Stack>
                <div style={Style.verticalLine}></div>
                <Stack
                  display="flex"
                  alignItems={"center"}
                  flexDirection="row"
                  gap="25px"
                >
                  <div style={Style.cancerTypeText}>Region</div>
                  <div style={Style.cancerText}>{regions?.name}</div>
                </Stack>
              </Stack>
            </div>
          </div>
          <div>
            <img src={loginInfo?.user_role === "ADMIN" ? pdfLogoData?.consent_logo_right : loginInfo?.consent_logo_right} style={{maxWidth: 184}} />
          </div>
        </Stack>
        <Stack position={"relative"}>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="center"
            gap="20px"
            alignItems={"center"}
            mt="30px"
            mb="10px"
          >
            <div style={Style.overviewText}>Patient Journey Overview</div>
            <Stack style={Style.suspicionCon}>
              <Stack
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="40px"
              >
                <div style={Style.overviewText}>{allInsightsDays?.total_days || 0} Days</div>
                <div style={{ ...Style.overviewText, color: !allInsightsDays?.total_diff_mode ? "#E65656" : "#62E89C" }}>
                {!allInsightsDays?.total_diff_mode ?
                <KeyboardArrowUpIcon fontSize="20px" style={{color: "#E65656"}} />:
                <KeyboardArrowDown fontSize="20px" style={{color: "#62E89C"}}/>
                } {allInsightsDays?.total_diff || 0}%
                </div>
              </Stack>
              <Stack style={Style.screenSus}>
                1st Screening suspicion - 1st Treatment Initiation
              </Stack>
            </Stack>
          </Stack>
          <Stack style={Style.dateCreatedText}>
            Last Updated : {new Date().getDate()} {getMonthName(new Date())}{" "}
            {new Date().getFullYear()} | {dateTime(new Date())}
          </Stack>
        </Stack>
        <Divider
          variant="middle"
          style={{ borderColor: "gray", borderStyle: "dashed", margin: 25 }}
        />
        <InputLabel
          style={{
            fontSize: 24,
            fontFamily: "Poppins-Medium",
            color: "#554886",
            marginLeft: '8px'
          }}
        >
          Program Overview
        </InputLabel>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",

            "& > :not(style)": {
              m: 1,
              minWidth: 360,
              height: 122,
            },
          }}
        >
          <Paper
            elevation={0}
            sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1,width: '100%' }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 40px",
                
              }}
            >

              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  //alignItems: "center",
                  justifyContent: "space-between",
                  padding: "20px 0",
                  gap: 30
                }}
              >

                    <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                      <InputLabel
                        style={{
                          fontSize: 13,
                          fontFamily: "Poppins-Medium",
                          color: "#237BBC",
                        }}
                      >
                        {"New Patients"}
                        <Tooltip title={notificationConfig?.NEW_PATIENTS_PO} arrow placement="right">
                            <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                        </Tooltip>
                      </InputLabel>
                      <Typography
                        style={{
                          fontSize: 22,
                          fontFamily: "Poppins-Semibold",
                          color: "#242424",
                        }}
                      >
                        {programOverview?.new_patients}
                      </Typography>
                    </Box>
                    <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                      <InputLabel
                        style={{
                          fontSize: 13,
                          fontFamily: "Poppins-Medium",
                          color: "#237BBC",
                        }}
                      >
                        {"Navigated Patients"}
                        <Tooltip title={notificationConfig?.NAVIGATED_PATIENTS_PO} arrow placement="right">
                            <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                        </Tooltip>
                      </InputLabel>
                      <Typography
                        style={{
                          fontSize: 22,
                          fontFamily: "Poppins-Semibold",
                          color: "#242424",
                        }}
                      >
                        {programOverview?.navigated_patients}
                      </Typography>
                    </Box>
                    <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                      <InputLabel
                        style={{
                          fontSize: 13,
                          fontFamily: "Poppins-Medium",
                          color: "#237BBC",
                        }}
                      >
                        {"Navigated Appointments"}
                        <Tooltip title={notificationConfig?.NAVIGATED_APPOINTMENTS_PO} arrow placement="right">
                            <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                        </Tooltip>
                      </InputLabel>
                      <Typography
                        style={{
                          fontSize: 22,
                          fontFamily: "Poppins-Semibold",
                          color: "#242424",
                        }}
                      >
                        {programOverview?.navigated_appointments}
                      </Typography>
                    </Box>

                <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                      <InputLabel
                        style={{
                          fontSize: 13,
                          fontFamily: "Poppins-Medium",
                          color: "#237BBC",
                        }}
                      >
                        {"Finalized Appointments"}
                        <Tooltip title={notificationConfig?.FINALIZED_APPOINTMENTS_PO} arrow placement="right">
                            <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                        </Tooltip>
                      </InputLabel>
                      <Typography
                        style={{
                          fontSize: 22,
                          fontFamily: "Poppins-Semibold",
                          color: "#242424",
                        }}
                      >
                        {programOverview?.finalized_appointments}
                      </Typography>
                    </Box>
                    <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                      <InputLabel
                        style={{
                          fontSize: 13,
                          fontFamily: "Poppins-Medium",
                          color: "#237BBC",
                        }}
                      >
                        {"Postponed Appointments"}
                        <Tooltip title={notificationConfig?.POSTPONED_APPOINTMENTS_PO} arrow placement="right">
                            <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                        </Tooltip>
                      </InputLabel>
                      <Typography
                        style={{
                          fontSize: 22,
                          fontFamily: "Poppins-Semibold",
                          color: "#242424",
                        }}
                      >
                        {programOverview?.postponed_appointments}
                      </Typography>
                    </Box>
                    <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                      <InputLabel
                        style={{
                          fontSize: 13,
                          fontFamily: "Poppins-Medium",
                          color: "#237BBC",
                        }}
                      >
                        {"Resolved barriers"}
                        <Tooltip title={notificationConfig?.RESOLVED_BARRIERS_PO} arrow placement="right">
                            <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                        </Tooltip>
                      </InputLabel>
                      <Typography
                        style={{
                          fontSize: 22,
                          fontFamily: "Poppins-Semibold",
                          color: "#242424",
                        }}
                      >
                        {barriesFigure?.resolved}
                      </Typography>
                    </Box>
                    <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                      <InputLabel
                        style={{
                          fontSize: 13,
                          fontFamily: "Poppins-Medium",
                          color: "#237BBC",
                        }}
                      >
                        {"Referred patients"}
                        <Tooltip title={notificationConfig?.REFERRED_PATIENTS_PO} arrow placement="right">
                            <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                        </Tooltip>
                      </InputLabel>
                      <Typography
                        style={{
                          fontSize: 22,
                          fontFamily: "Poppins-Semibold",
                          color: "#242424",
                        }}
                      >
                        {referrals?.reduce((accumulator, currentValue) => accumulator + currentValue?.final_total_patient, 0)}
                      </Typography>
                    </Box>


              </Box>


            </Box>
          </Paper>
        </Box >
        {category?.["Journey Phase"]?.isChecked && <JourneyPhase />}
        {category?.["Process"]?.isChecked && <ProcessStatistics export={true} />}
        {category?.["Completion Rate"]?.isChecked && <Completion export={true} />}
        {category?.["Barriers"]?.isChecked && <Barriers />}
        <StatisticsOtherSection category={category} />
      </div>
    );
  };
  const _dropdownSelectedItems = (item) => {
    const { regions, cancerTypes, duration, month, year } = item;
    setRegions(regions);
    setCancerTypes(cancerTypes);
    setDuration(duration);
    setMonth(month);
    setYear(year);
  };

  const _mapAllInsightsBarrier = (allInsightsData) => {
    const { barrier_figure, risk_factor, referrals, travel, app_usage, death, notification_config, program_overview } = allInsightsData;
    store.dispatch(getBarriesFigure(barrier_figure));
    store.dispatch(getRiskFactors(risk_factor));
    store.dispatch(getReferrals(referrals));
    store.dispatch(getTravel(travel));
    store.dispatch(getAppUsage(app_usage));
    store.dispatch(getDeath(death));
    store.dispatch(getProgramOverview(program_overview));
    store.dispatch(getNotificationConfig(notification_config));
  };
  const _mapAllInsightsReport = (allInsightsData) => {
    const { data } = allInsightsData;
    if (data.length) {
      store.dispatch(getJournetPhase(data.map((e) => e.journey_phase) || []));
      store.dispatch(getProcess(data.map((e) => e.process) || []));
      store.dispatch(
        getCompletionRate(data.map((e) => e.completion_ratio) || [])
      );
      store.dispatch(getBarries(data.map((e) => e.barrier) || []));
      store.dispatch(getAllInsightsDays({total_days: allInsightsData?.total_days, total_diff: allInsightsData?.total_diff, total_diff_mode: allInsightsData?.total_diff_mode}));
    }
  };

  let { programOverview, barriesFigure, referrals, notificationConfig } = store?.getState()?.nlaReducer;

  return (
    <ThemeProvider theme={theme}>
      {renderPdfContent()}
      <Box
        ref={pdfRef}
        sx={{ height: "100vh", backgroundColor: "#F6F7FA", overflow: "scroll" }}
      >
        {isLoading && (
          <Box sx={Style.spinner}>
            <CustomLoader />
          </Box>
        )}
        <TopNavigation
          navPath={`/reports/patient-journey${window.location.search}`}
          title={"All Insights"}
          button={{
            startIcon: ExportI,
            name: "Export",
            _onTopNavButtonCallBack,
            height: 31,
          }}
        />
        {/* <Box sx={{ margin: 3 }}>
          <CommonDropDown callBack={_dropdownSelectedItems} />
        </Box> */}
        <Box sx={{ margin: 3 }}>
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                // margin: '20px',
                marginTop: '10px',
                gap: 4,
                justifyContent: 'space-between'
            }}
        >
          <Box style={{ padding: 0, flex: 1 }}>
              <InputLabel style={Style.dropdownTitle}>
                  Region
              </InputLabel>
              <Select
                  value={regions.selectedItems}
                  onChange={handleRegionChange}
                  style={Style.select}
              >
                  {(regions?.list || []).map((item) => {
                      return <MenuItem value={item.value}>{item.name}</MenuItem>;
                  })}
              </Select>
          </Box>
          <Box style={{ padding: 0, flex: 1 }}>
              <InputLabel id="cancertype" style={Style.dropdownTitle}>
                  Cancer Type
              </InputLabel>
              <Select
                  value={cancerTypes.selectedItems}
                  onChange={handleCancerTypeChange}
                  style={Style.select}
              >
                  {(cancerTypes?.list || []).map((item) => {
                      return <MenuItem value={item.value}>{item.name}</MenuItem>;
                  })}
              </Select>
          </Box>
          <Box style={{ padding: 0, flex: 1 }}>
              <InputLabel id="duration" style={Style.dropdownTitle}>
                  Duration
              </InputLabel>
              <Select
                  value={duration.selectedItems}
                  onChange={handleDurationChange}
                  style={Style.select}
              >
                  {(duration.list || []).map((item) => {
                      return <MenuItem value={item.value}>{item.name}</MenuItem>;
                  })}
              </Select>
          </Box>
          <Box style={{ padding: 0, flex: 1 }}>
              <InputLabel id="month" style={Style.dropdownTitle}>
                  Month
              </InputLabel>
              <Select
                  value={month.selectedItems}
                  onChange={handleMonthChange}
                  style={Style.select}
              >
                  {(month.list || []).map((item) => {
                      return <MenuItem value={item.value} >{item.name}</MenuItem>;
                  })}
              </Select>
          </Box>
          <Box style={{}}>
              <InputLabel id="year" style={Style.dropdownTitle}>
                  Year
              </InputLabel>
              <Select
                  value={year.selectedItems}
                  onChange={handleYearChange}
                  
                  // IconComponent={(props) => (<AiOutlineDown  {...props}/>)}
                  style={Style.select}
              >
                  {(year.list || []).map((item) => {
                      return <MenuItem value={item.value}>{item.name}</MenuItem>;
                  })}
              </Select>
          </Box>
          <Box sx={{ display: "flex", height: 60, alignItems: "end" }}>
              <Button
                  variant="contained"
                  style={{
                      height: 44, marginBottom: "", width: 109, background: "#546FE6", textTransform: 'none', fontSize: 18, fontFamily: 'Poppins-SemiBold'
                  }}
                  onClick={() => {
                    setonLoadApi(true)
                    localStorage.setItem("selectedCancerType", cancerTypes?.selectedItems[0])
                    navigate(`/reports/allinsights?region=${regions?.selectedItems[0]}&cancerTypes=${cancerTypes?.selectedItems[0]}&duration=${duration?.selectedItems[0]}&month=${month?.selectedItems[0]}&year=${year?.selectedItems[0]}`,{replace: true});
                  }}
              >
                  Go
              </Button>
          </Box>
        </Box>
        </Box>

        <Divider
          variant="middle"
          style={{ borderColor: "gray", borderStyle: "dashed", margin: 25 }}
        />

        <>
          <Box style={{ padding: "0px 20px" }}>
            {!isLoading && (
              <>
                <InputLabel
                  style={{
                    fontSize: 24,
                    fontFamily: "Poppins-Medium",
                    color: "#554886",
                    marginLeft: '8px'
                  }}
                >
                  Program Overview
                </InputLabel>

                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",

                    "& > :not(style)": {
                      m: 1,
                      minWidth: 360,
                      height: 122,
                    },
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1,width: '100%' }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 40px",
                        
                      }}
                    >

                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          //alignItems: "center",
                          justifyContent: "space-between",
                          padding: "20px 0",
                          gap: 30
                        }}
                      >

                            <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                              <InputLabel
                                style={{
                                  fontSize: 13,
                                  fontFamily: "Poppins-Medium",
                                  color: "#237BBC",
                                }}
                              >
                                {"New Patients"}
                                <Tooltip title={notificationConfig?.NEW_PATIENTS_PO} arrow placement="right">
                                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                                </Tooltip>
                              </InputLabel>
                              <Typography
                                style={{
                                  fontSize: 22,
                                  fontFamily: "Poppins-Semibold",
                                  color: "#242424",
                                }}
                              >
                                {programOverview?.new_patients}
                              </Typography>
                            </Box>
                            <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                              <InputLabel
                                style={{
                                  fontSize: 13,
                                  fontFamily: "Poppins-Medium",
                                  color: "#237BBC",
                                }}
                              >
                                {"Navigated Patients"}
                                <Tooltip title={notificationConfig?.NAVIGATED_PATIENTS_PO} arrow placement="right">
                                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                                </Tooltip>
                              </InputLabel>
                              <Typography
                                style={{
                                  fontSize: 22,
                                  fontFamily: "Poppins-Semibold",
                                  color: "#242424",
                                }}
                              >
                                {programOverview?.navigated_patients}
                              </Typography>
                            </Box>
                            <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                              <InputLabel
                                style={{
                                  fontSize: 13,
                                  fontFamily: "Poppins-Medium",
                                  color: "#237BBC",
                                }}
                              >
                                {"Navigated Appointments"}
                                <Tooltip title={notificationConfig?.NAVIGATED_APPOINTMENTS_PO} arrow placement="right">
                                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                                </Tooltip>
                              </InputLabel>
                              <Typography
                                style={{
                                  fontSize: 22,
                                  fontFamily: "Poppins-Semibold",
                                  color: "#242424",
                                }}
                              >
                                {programOverview?.navigated_appointments}
                              </Typography>
                            </Box>

                        <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                              <InputLabel
                                style={{
                                  fontSize: 13,
                                  fontFamily: "Poppins-Medium",
                                  color: "#237BBC",
                                }}
                              >
                                {"Finalized Appointments"}
                                <Tooltip title={notificationConfig?.FINALIZED_APPOINTMENTS_PO} arrow placement="right">
                                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                                </Tooltip>
                              </InputLabel>
                              <Typography
                                style={{
                                  fontSize: 22,
                                  fontFamily: "Poppins-Semibold",
                                  color: "#242424",
                                }}
                              >
                                {programOverview?.finalized_appointments}
                              </Typography>
                            </Box>
                            <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                              <InputLabel
                                style={{
                                  fontSize: 13,
                                  fontFamily: "Poppins-Medium",
                                  color: "#237BBC",
                                }}
                              >
                                {"Postponed Appointments"}
                                <Tooltip title={notificationConfig?.POSTPONED_APPOINTMENTS_PO} arrow placement="right">
                                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                                </Tooltip>
                              </InputLabel>
                              <Typography
                                style={{
                                  fontSize: 22,
                                  fontFamily: "Poppins-Semibold",
                                  color: "#242424",
                                }}
                              >
                                {programOverview?.postponed_appointments}
                              </Typography>
                            </Box>
                            <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                              <InputLabel
                                style={{
                                  fontSize: 13,
                                  fontFamily: "Poppins-Medium",
                                  color: "#237BBC",
                                }}
                              >
                                {"Resolved barriers"}
                                <Tooltip title={notificationConfig?.RESOLVED_BARRIERS_PO} arrow placement="right">
                                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                                </Tooltip>
                              </InputLabel>
                              <Typography
                                style={{
                                  fontSize: 22,
                                  fontFamily: "Poppins-Semibold",
                                  color: "#242424",
                                }}
                              >
                                {barriesFigure?.resolved}
                              </Typography>
                            </Box>
                            <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", }}>
                              <InputLabel
                                style={{
                                  fontSize: 13,
                                  fontFamily: "Poppins-Medium",
                                  color: "#237BBC",
                                }}
                              >
                                {"Referred patients"}
                                <Tooltip title={notificationConfig?.REFERRED_PATIENTS_PO} arrow placement="right">
                                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                                </Tooltip>
                              </InputLabel>
                              <Typography
                                style={{
                                  fontSize: 22,
                                  fontFamily: "Poppins-Semibold",
                                  color: "#242424",
                                }}
                              >
                                {referrals?.reduce((accumulator, currentValue) => accumulator + currentValue?.final_total_patient, 0)}
                              </Typography>
                            </Box>


                      </Box>


                    </Box>
                  </Paper>
                </Box >
                <JourneyPhase />
                <ProcessStatistics />
                <Completion />
                <Barriers />
                <StatisticsOtherSection />
              </>
            )}
          </Box>
        </>
      </Box>

      <Outlet />
    </ThemeProvider>
  );
};

// export default AllInsights;

const mapStateToProps = (state) => ({
  allInsightsCategory: state?.nlaReducer?.allInsightsCategory,
});

export default connect(mapStateToProps)(AllInsights);
