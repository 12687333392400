import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState, useEffect } from 'react';
import { Button, Link, Box, CircularProgress, Tooltip } from '@mui/material';
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom';
import Popup from '../popup';
import SettingsPop from '../settingsPopup'
import ToggleWithText from '../../../common/control/togglewithtext';
import { ReactComponent as EditIcon } from '../../../assets/webapp_icon/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/webapp_icon/delete.svg';
import axios from '../../../http/axios'
import { API, CustomWidthTooltip } from '../../../constants/constant';
import { async } from 'q';
import CommonPagination from '../../../common/control/commonpagination';

const columns = [
  { id: "dr_name", label: "Care Provider", minWidth: 200, l: 33 },
  { id: "facilityname", label: "Facility", minWidth: 200 },
  { id: "dr_speciality", label: "Specialization", minWidth: 200 },
  { id: "status", label: "Status", minWidth: 70 },
  { id: "action", label: "Action", minWidth: 50, align: "center" },
];

const style = {
  spinner: {
    display: 'flex', position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  }
}



function createData(name, facility, role,) {
  return { name, facility, role };
}


const DoctorTable = (props) => {
  const {state} = useLocation();
  const { specilization, allFacility, page, setPage } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [allDoctor, setAllDoctor] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (newPage) => {
        setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(1);
  };

  useEffect(() => { 
    if(!state) 
    fetchDoctor()
  }, [props?.debounceQuery ,page,rowsPerPage, state]);

  const fetchDoctor = () => {
    setIsLoading(true)
    axios.get(API.getFetchDoctors, {
      params: {
        search_text: props?.query || '',
        page_no: page,
        limit: rowsPerPage,
      },
    })
      .then((res) => {
        console.log(res);
        const { data: { data: { doctor } } } = res
        setAllDoctor(doctor)
        setTotalCount(res?.data?.data?.totalCount)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log("An error occurred:", error);
        setIsLoading(false)
      });
  }

  const updateStatus = (isenable, item) => {
    setIsLoading(true)
    const data = {
      status: item.status == 0 ? 1 : 0,
      dr_code: parseInt(item.dr_code)
    };
  
    axios.put(API.updateDoctorStatus,
      data,
    )
      .then((res) => {
        console.log("Data updated successfully:", res.data);
        fetchDoctor();
        setIsLoading(false)
      })
      .catch((error) => {
        console.log("An error occurred:", error);
        setIsLoading(false)
      });
  }

  const navigate = useNavigate();
  const onEditPopup = (rowItem) => {
    if(rowItem?.status == "0") return
    navigate('edit-doctor', {
      state: {
        title: 'Edit Care Provider!',
        isEdit: true,
        rowItem,
        specilization: { ...specilization, selectedItems: [rowItem.specilization_id], name: rowItem.dr_speciality },
        allFacility: { ...allFacility, selectedItems: [rowItem.facilitycode], name: rowItem.facilityname },
      }
    })
  }

  const deleteDoctor = (rowItem) => {
    navigate('delete', { state: { title: "Delete", res: "Are you sure you want to delete the Care Provider ?", apiPath: API.deleteNLADoctor, apiData: { id: rowItem?.dr_code } } })
  }

  return (
    <>
      <Box sx={{width: "100%", marginBottom: 2, boxShadow: '10px 24px 30px 10px #0000000F', border: "1px solid #D3DDEA",borderRadius: "8px", background: '#FFFFFF', }}>

        {isLoading && <Box sx={style.spinner}>
          <CircularProgress />
        </Box>}

        <TableContainer sx={{
          height: "calc(100vh - 220px)", overflow: "auto", borderRadius: "8px", 
        }}>
          <Table >
            <TableHead style={{position: "sticky",top: 0,zIndex: 1, boxShadow: '0px 15px 10px -10px lightgray',}}>
              <TableRow style={{  background: '#F9F9F9 0% 0% no-repeat padding-box', height: "60px" }}>
                {/* <TableRow style={{boxShadow:'none !important'}}> */}
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Medium' }}
                  >
                    <Box style={{ paddingLeft: column.l }}>
                      {column.label}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(allDoctor || [])
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                      style={{ height: 60 }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} style={{ color: '#666666', fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
                            {column.id == "addkey" ? (
                              <Link
                                href="#"
                                underline="always"
                                style={{
                                  color: "#1542F2",
                                  fontWeight: 600,
                                  fontFamily: "Poppins-Regular",
                                }}
                              >
                                Add Keywords
                              </Link>
                            ) : column.id == "status" ? (
                              <>
                              
                                <ToggleWithText isEdit={true} isEnabled={parseInt(row?.status) == 1} callBack={(e) => { updateStatus(e, row) }} />
                              </>
                            ) :

                              column.id == "action" ? (
                                <div className='d-flex flex-row table-action gap-3'>
                                  <div onClick={() => onEditPopup(row)}>
                                    <EditIcon style={row?.status == "0" ? {
                                                        cursor: "not-allowed", marginRight: '10px'
                                                    } : {cursor: "pointer", marginRight: '10px'}} />
                                  </div>
                                  <div onClick={() => deleteDoctor(row)}>
                                    <DeleteIcon style={ {cursor: "pointer"}} />
                                  </div>
                                </div>
                              ) : column.id == 'dr_name' ? (
                                <Box style={{ marginLeft: column.l }}>
                                  {row?.isdoctor == "1" ? 'Dr. '+value : value}
                                </Box>
                              ) :
                              (
                                <Box style={{ marginLeft: column.l }}>
                                  {
                                  column.id == "facilityname" ? 
                                  row?.facility_status == "1" ? <CustomWidthTooltip title={value} arrow><span style={{maxWidth: 420, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display: 'block', width: 'fit-content'}}>{value}</span></CustomWidthTooltip> : ""
                                  :
                                  value
                                  }
                                </Box>
                              )
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
                {isLoading ? null : allDoctor?.length == 0 && ( <TableRow> <TableCell colSpan={6} style={{textAlign: 'center'}}>No records found</TableCell> </TableRow> )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CommonPagination
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
    </>
  );
};

export default DoctorTable;
