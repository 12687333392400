import * as React from "react";
import {
    IconButton,
    InputLabel,
    Paper,
    Tooltip
} from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/material";
import Divider from '@mui/material/Divider';
import store from "../../../../../redux/store/store";
import { renderPieData } from "../../../../../constants/constant";
import { PieChart, pieArcLabelClasses } from '@mui/x-charts';
import { BsFillSquareFill } from "react-icons/bs";
import Helper from '../../../../../assets/images/helper.svg';

const RADIAN = Math.PI / 180;

const InformedConsent = (props) => {
    const screenIndex = 4
    const [process, setProcess] = useState([])
    const [patientData, setPatientData] = useState(undefined)
    const [noDataPie, setNoDataPie] = useState(false)
    let { notificationConfig } = store?.getState()?.nlaReducer;

    React.useEffect(() => {
        let { process } = store?.getState()?.nlaReducer;
        process = (process || [])?.find(e => e.journey_step_seq == screenIndex) || [];
        setProcess(process?.journey_data || [])
        setNoDataPie(process?.no_data)
        setPatientData(_setPatientData(process?.patient_data))
    }, [store?.getState()?.nlaReducer?.process])


    const _setPatientData = (item) => {
        return {
            ...item,
            informedConsent: {
                value: item?.informed_consent_patient || 0,
            },
            mdtMeetings: {
                value: item?.mdt_patient || 0,
            },
            disclosures: {
                value: item?.disclosures_patient || 0,
            },
        }
    }

    return (
        <>
            <Paper
                elevation={0}
                sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: "10px" }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 20px"
                    }}
                >
                    <InputLabel
                        style={{
                            fontSize: 14,
                            fontFamily: "Poppins-Medium",
                            color: "#838383",
                            paddingBottom: 5,
                            marginTop: 0,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {"Appointments"}
                        <Tooltip title={notificationConfig?.PROCESS_APPOINTMENT} arrow placement="right">
                            <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                        </Tooltip>
                    </InputLabel>
                    <Box
                        style={{
                            // display: "flex",
                            flexDirection: "row",
                            // alignItems: "center",
                            justifyContent: 'space-between',
                            // padding: "20px 0",
                            // height: 240,
                            // overflowY: 'auto',
                            paddingTop: 0
                        }}
                    >
                        <Box
                            style={{
                                display: "flex", flexDirection: "column", alignItems: 'start', height: props?.export ? 2000 : 150,
                                overflowY: 'auto',
                            }}
                        >
                            {(process || []).map((processItem, i) => {
                                return (
                                    processItem?.data.map((milestone, key) => {
                                        return (
                                            <Box sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: 'space-between', width: '100%', paddingRight: '5px' }} key={key}>
                                                <span
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins-SemiBold",
                                                        color: "#00133A",
                                                        width: 30,
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    {milestone.total_patient}
                                                </span>
                                                <div
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins-Medium",
                                                        color: "#838383",
                                                        display: 'flex',
                                                        gap: 2
                                                    }}
                                                >
                                                    <BsFillSquareFill color={milestone.step_color} style={{ marginRight: 6, width: 10, marginTop: 4 }} />
                                                    <span style={{ width: 'fit-content' }}>{milestone.appTitle}</span>
                                                </div>
                                            </Box>
                                        )
                                    })

                                )
                            })}


                        </Box>
                        {!noDataPie ?
                        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginBottom: 30, marginTop: 20 }}>
                            <Box style={{ display: "flex", alignItems: 'center', width: 250, height: 250 }}>
                                <PieChart
                                    series={[
                                        {
                                            innerRadius: 0,
                                            outerRadius: 80,
                                            data: renderPieData(process)?.[0],
                                            arcLabel: (item) => `${item.value ? item.value : ''}`
                                        },
                                        {
                                            innerRadius: 81,
                                            outerRadius: 125,
                                            data: renderPieData(process)?.[1],
                                            arcLabel: (item) => `${item.value ? item.value : ''}`
                                        },
                                    ]}
                                    sx={{
                                        [`& .${pieArcLabelClasses.root}`]: {
                                            fill: 'white',
                                            fontWeight: 'bold',
                                            fontSize: 10,
                                            width: '30px',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        },
                                    }}
                                    width={250}
                                    height={250}
                                    slotProps={{
                                        legend: { hidden: true },
                                    }}
                                    colors={process.map(item => item?.step_color)}
                                    margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
                                />
                            </Box>
                            </div> :
                            <div style={{ marginTop: 20, marginBottom: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', }}><div style={{ width: 250, height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: '1px solid #b9b1b1', background: '#f44f4f', color: '#fff' }}>No Data</div></div>}
                    </Box>
                    <Divider variant="middle" />
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: 'space-between',
                                padding: "10px 0",
                            }}>
                            <InputLabel
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                }}
                            >
                                {"INFORMED CONSENT"}
                            </InputLabel>


                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    padding: "10px 0",
                                }}
                            >
                                <h3
                                    style={{
                                        fontSize: 25,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                        paddingRight: 10
                                    }}
                                >
                                    {patientData?.informedConsent.value}
                                </h3>
                            </Box>
                        </Box>
                        <Divider
                            style={{
                                height: 1,
                                width: 40,
                                transform: "rotate(90deg)",
                                background: "gray",
                            }}
                        /> */}
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: 'space-between',
                                padding: "10px 0",
                            }}>
                            <InputLabel
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                }}
                            >
                                {"MDT MEETINGS"}
                                <Tooltip title={notificationConfig?.MDT_MEETING_COUNT} arrow placement="right">
                                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                                </Tooltip>
                            </InputLabel>
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    // alignItems: "center",
                                    justifyContent: 'space-between',
                                    padding: "10px 0",
                                }}>
                                <h3
                                    style={{
                                        fontSize: 25,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                        paddingRight: 10
                                    }}
                                >
                                    {patientData?.mdtMeetings.value}
                                </h3>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: 'space-between',
                                padding: "10px 0",
                            }}>
                            <InputLabel
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                }}
                            >
                                {"DISCONTINUED PATIENTS"}
                                <Tooltip title={notificationConfig?.DISCONTINUE_PATIENT} arrow placement="right">
                                    <IconButton><img src={Helper} alt="" style={{width: 18}} /></IconButton>
                                </Tooltip>
                            </InputLabel>
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    // alignItems: "center",
                                    justifyContent: 'space-between',
                                    padding: "10px 0",
                                }}>
                                <h3
                                    style={{
                                        fontSize: 25,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                        paddingRight: 10
                                    }}
                                >
                                    {patientData?.discontinue_patient}
                                </h3>
                            </Box>
                        </Box>
                    {/* <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: 'space-between',
                                padding: "10px 0",
                            }}>
                            <InputLabel
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                }}
                            >
                                {"DISCLOSURES"}
                            </InputLabel>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    padding: "10px 0",
                                }}
                            >
                                <h3
                                    style={{
                                        fontSize: 25,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                        paddingRight: 10
                                    }}
                                >
                                    {patientData?.disclosures.value}
                                </h3>
                            </Box>
                        </Box>
                    </Box> */}
                </Box>
                <Box>
                    <div style={{textAlign: 'center', fontSize: 14, fontWeight: '600', marginBottom: 10}}>*Old data prior to change of 'Appointment titles'</div>
                </Box>
            </Paper >
        </>
    )
}
export default InformedConsent