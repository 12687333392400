import { Box, Button, Typography, Modal, TextField, Label, FormLabel, Select, InputLabel, MenuItem, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { API, MOCKUP_FACILITY, MOCKUP_LOCATION, MOCKUP_REGION, MOCKUP_ROLE, MOCKUP_YEAR } from "../../../constants/constant";
import axios from '../../../http/axios';
import _ from "lodash";
import store from "../../../redux/store/store";
import '../../../assets/css/userRole.css';
import { useSelector } from "react-redux";
const style = {
    container: {
        top: '50%',
        left: '50%',
        alignItem: 'center',
        transform: 'translate(-50%, -50%)',
        width: "39%",
        // height: '57vh',
        maxHeight: "580px",
        height: "90%",
        bgcolor: '#ffffff',
        boxShadow: 15,
        borderRadius: 10,
        position: 'fixed',
        outline: 'none'
    },
    Additem: {
        display: 'flex',
        flexDirection: 'column',
        // height: 442,
        gap: 20,
        margin: '20px 30px 0px 30px',
        height: "85%",
        overflow: "auto"
    },
    close: {
        position: 'absolute',
        cursor: 'pointer',
        top: -20,
        right: -15,
        width: 55,
        height: 55,
        backgroundColor: '#fda945',
        borderColor: '#fff',
        borderRadius: 30,
        border: '2px solid #fff',
        textAlign: 'center'
    },
    button: {
        height: 50,
        marginBottom: 4,
        width: '-webkit-fill-available',
        marginTop: 20,
        borderRadius: 25,
        background: "#546FE6",
        textTransform: 'none',
        fontSize: 20,
        fontFamily: 'Poppins-SemiBold'
    },
    dropdownTitle: {
        fontSize: 14,
        fontFamily: "Poppins-Regular",
        color: "#464A53",
    },
    select: {
        display: 'flex',
        minWidth: 170,
        height: 46.48,
        background: "#ffffff",
        borderRadius: 4,
        border: '0px solid #f4f6fa',
        fontSize: 18,
        fontFamily: 'Poppins-Regular'
    },
    select1: {

        color: '#464A53',
        fontSize: 18,
        fontFamily: 'Poppins-Regular'
    },
    spinner: {
        display: 'flex', position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        alignItems: 'center',
        justifyContent: 'center',
    }

};

const AddFacility = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const { state } = useLocation();
    console.log('state', state)
    const navigate = useNavigate();
    const { country_id } = useSelector(state => state.auth)

    const [region, setRegion] = useState({
        list: [],
        selectedItems: [],
        value: "",
        error: false,
    });

    const [location, setLocation] = useState({
        list: [],
        selectedItems: [],
        value: "",
        error: false,
    });
    const [facilityName, setFacilityName] = useState(state?.rowItem?.facilityname);
    const [facilityAddress, setFacilityAddress] = useState(state?.rowItem?.facility_address);
    const [errors, setErrors] = useState({
        facilityName: false,
        facilityAddress: false,
        region: false,
        location: false
    });

    const handleClose = () => navigate(-1);

    useEffect(() => {
        // let { regions_list } = store?.getState()?.auth;
        axios.get(API.getAllRegionsV2, { params: { country_id } }).then((res) => {
            let _data = res.data.data
            const regions_list = (_data || []).map(region => {
                return {
                    name: region.region_name,
                    value: region.region_id.toString(),
                    _id: region.region_id.toString(),
                }
            })
            setRegion({
                list: regions_list,
                selectedItems: [state?.rowItem?.region_id || 0],
                name: regions_list[0]?.name,
                error: false,
            })
        }).catch((error) => {
            alert(error?.message)
        })

    }, []);
    useEffect(() => {
        if (region.selectedItems.length) {
            setLocation({ ...location, selectedItems: [] })
            // Fetch location data based on the selected region
            axios.get(API.getLocationByRegionList, {
                params: {
                    region_id: region.selectedItems[0]?.toString()
                }
            })
                .then((res) => {
                    const data = res?.data?.data;
                    const _location_data = (data || []).map(loc => {
                        return {
                            name: loc.location_name,
                            value: loc.location_id.toString(),
                            _id: loc.location_id.toString(),
                        }
                    })
                    setLocation({
                        ...location,
                        list: _location_data,
                        selectedItems: [state?.rowItem?.location_id || _location_data[0]?.value],
                        name: _location_data[0]?.name,
                        error: false,
                    })
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.error("An error occurred:", error);
                    setIsLoading(false)
                });
        }
    }, [region]);


    const handleRegionChange = (event) => {
        const {
            target: { value },
        } = event;
        setRegion({
            ...region,
            selectedItems: typeof value === "string" ? value.split(",") : value,
            name: value
        });
    };
    const handleLocationChange = (event) => {
        const {
            target: { value },
        } = event;
        setLocation({
            ...location,
            selectedItems: typeof value === "string" ? value.split(",") : value,
            name: value
        });
    };


    const addEditFacility = () => {

        let errorsItem = {};
        if (!facilityName?.trim()) {
            errorsItem['facilityName'] = true;
        }
        if (!facilityAddress?.trim()) {
            errorsItem['facilityAddress'] = true;
        }
        if (!location.name?.trim()) {
            errorsItem['location'] = true;
        }
        if (!region.name?.trim()) {
            errorsItem['region'] = true;
        }
        const addData = {
            facilityname: facilityName,
            regionid: region.selectedItems[0].toString(),
            locationid: location.selectedItems[0],
            facilityaddress: facilityAddress
        };

        var editData = JSON.stringify({
            facilityname: facilityName,
            locationid: location.selectedItems[0],
            regionid: region.selectedItems[0].toString(),
            facilitycode: state?.rowItem?.facilitycode,
            facilityaddress: facilityAddress
        });

        if (!_.isEmpty(errorsItem)) {
            setErrors({ ...errors, ...errorsItem });
            return;
        }
        setIsLoading(true)
        if (state?.isEdit)
            axios.put(API.updateFacility, editData)
                .then((res) => {
                    console.log("Data updated successfully:", res?.data);
                    handleClose()
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.error("An error occurred:", error);
                    alert(error?.message)
                    setIsLoading(false)
                });
        else
            axios.post(API.addFacility, addData)
                .then((res) => {
                    console.log("Data added successfully:", res?.data);
                    handleClose()
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.error("An error occurred:", error);
                    alert(error?.message)
                    setIsLoading(false)
                });
    };

    return (
        <>

            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style.container}>
                    <Box style={style.close}>
                        <MdOutlineClose size={30} style={{ color: "#fff", marginTop: 10 }} onClick={handleClose}></MdOutlineClose>
                    </Box>
                    <Box sx={{ padding: '30px 30px 30px 30px', height: "100%", overflow: 'hidden' }}>
                        <Typography id="modal-modal-description" sx={{ mt: 1, color: '#546FE6', fontSize: 28, fontFamily: 'Montserrat-Bold' }} >
                            {state?.title}
                        </Typography>
                        <Box style={style.Additem}>
                            <Box>
                                <FormLabel id="facilityname-addfacility" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Facility Name<span style={{ fontFamily: "Poppins-Regular", fontSize: 16, color: "#EE2D2D", paddingLeft: 4 }}>*</span></FormLabel>
                                <TextField className="select1" id="facility-input" fullWidth variant="standard" sx={{ color: '#464A53', '& .MuiInputBase-input': { fontSize: '18px', fontFamily: 'Poppins-Regular' }, borderBottom: errors?.facilityName ? '1px solid red' : "1px solid grey", }} value={facilityName}
                                    onChange={(event) => setFacilityName(event.target.value)}
                                    onFocus={() => setErrors({ ...errors, firstName: false })}
                                    InputProps={{
                                        disableUnderline: true,
                                    }} />
                            </Box>
                            <Box>
                                <FormLabel id="facilityaddress-addfacility" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Facility Address<span style={{ fontFamily: "Poppins-Regular", fontSize: 16, color: "#EE2D2D", paddingLeft: 4 }}>*</span></FormLabel>
                                <TextField className="select1" id="facility-input" fullWidth variant="standard" sx={{ color: '#464A53', '& .MuiInputBase-input': { fontSize: '18px', fontFamily: 'Poppins-Regular' }, borderBottom: errors?.facilityAddress ? '1px solid red' : "1px solid grey", }} value={facilityAddress}
                                    onChange={(event) => setFacilityAddress(event.target.value)}
                                    onFocus={() => setErrors({ ...errors, firstName: false })}
                                    // multiline
                                    // rows={2}
                                    // maxRows={2}
                                    InputProps={{
                                        disableUnderline: true,
                                    }} />
                            </Box>

                            <Box>
                                <FormLabel id="region-addfacility" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Region<span style={{ fontFamily: "Poppins-Regular", fontSize: 16, color: "#EE2D2D", paddingLeft: 4 }}>*</span></FormLabel>
                                <Select
                                    value={region.selectedItems}
                                    variant="standard"
                                    onChange={handleRegionChange}
                                    style={style.select}
                                >
                                    {(region.list || []).map((item) => {
                                        return <MenuItem value={item.value}>{item.name}</MenuItem>;
                                    })}
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel id="location-addfacility" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Location<span style={{ fontFamily: "Poppins-Regular", fontSize: 16, color: "#EE2D2D", paddingLeft: 4 }}>*</span></FormLabel>
                                <Select
                                    value={location.selectedItems}
                                    variant="standard"
                                    onChange={handleLocationChange}
                                    style={style.select}
                                >
                                    {(location.list || []).map((item) => {
                                        return <MenuItem value={item.value}>{item.name}</MenuItem>;
                                    })}
                                </Select>
                            </Box>
                            <Button
                                variant="contained"
                                style={style.button}
                                onClick={addEditFacility}
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress color="inherit" /> : "Save"}
                            </Button>

                        </Box>
                    </Box>
                </Box>
            </Modal >
        </>
    );
};
export default AddFacility