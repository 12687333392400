import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import '../../assets/css/home.css'
import Barrier from '../../assets/images/barrier.svg';
import User from '../../assets/images/user.svg';
import { InputLabel, Box } from '@mui/material';
import BackgroundBg from '../../assets/images/logo-bg.png'
import Back from '../../assets/images/back.png'
import store from '../../redux/store/store';
import { setLogout } from '../../redux/actions/actions';
const Welcome = () => {
    let { loginInfo } = store?.getState()?.auth;
    const navigate = useNavigate();
    const navigateSplash = () => {
        navigate('/');
    };
    const navigateUserCredentail = () => {
        navigate('/user-credential');
    };
    const navigateBarriers = () => {
        navigate('/welcome/barriers');
    };
    const navigateLogin = () => {
        store.dispatch(setLogout());
        localStorage.clear();
        navigate('/', { replace: true });
    };
    return (
        <>
            <div style={{ backgroundImage: `url(${BackgroundBg})`, width: '100%', height: '549px' }}>
                <div className='outer'>
                    <div className='inner-for'>
                        <p className="login-par-mobile">Welcome Patient Navigator !</p>
                        <p className="login-innerpar2">Please select a section that you want to visit.</p>
                        <div className='d-flex flex-row justify-content-center icons-row'>

                            <div className='d-flex flex-column col-2 inner-col' onClick={navigateUserCredentail}>
                                <img className='nla-img' src={User} alt="" />
                                <p className='setting-par'>User Credentials</p>
                            </div>
                            <div className='d-flex flex-column col-2 inner-col' onClick={navigateBarriers}>
                                <img className='nla-img' src={Barrier} alt="" />
                                <p className='setting-par'>Barriers</p>
                            </div>

                        </div>

                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0px 56px', alignItems: 'center' }}>
                            <InputLabel
                                style={{
                                color: '#000',
                                marginTop: 80,
                                fontSize: 25,
                                // cursor: 'pointer',
                                // textDecoration: 'underline',
                                // fontFamily: 'Poppins-Bold'
                                fontSize: 13
                                }}
                                // onClick={onExit}
                            >
                                {`${loginInfo?.app_version}`}
                            </InputLabel>
                            <InputLabel
                                style={{
                                    color: '#546FE6',
                                    marginTop: 80,
                                    fontSize: 25,
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                    fontFamily: 'Poppins-Bold'
                                }}
                                onClick={navigateLogin}
                            >
                                {"Exit"}
                            </InputLabel>
                        </Box>
                    </div>
                </div>
                <Outlet />
            </div >
        </>
    )
}
export default Welcome