import React, { useEffect } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';

import {
  InputLabel,
  Grid
} from "@mui/material";
import { Box } from "@mui/material";
import "../../../assets/css/theme.css";
import { BsCaretDownFill } from "react-icons/bs";
import { BsCaretUpFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import "../../../assets/css/patientJourney.css";
import { ProgressWithDynamicTooltip } from "../../../common/control/progresswithdynamictooltip";
import { useSelector } from 'react-redux';
import { patientJourneyImages } from '../../../constants/constant';

const PatientJourneyBox = (props) => {

  const { patientJourneyData, dropdownData } = useSelector(state => state.rocheReducer)


  useEffect(() => {
    console.log(patientJourneyData,"patientJourneyData")
    
  },[patientJourneyData])

  const navigate = useNavigate();

  const navigateAllDetails = (item) => {
    console.log(item,dropdownData,"dropdownData123123")
    navigate(`/reports/barrier-investigation`, { state: { ...item, ...dropdownData } });
  };

  const navigateAllBarriers = (item) => {
    navigate(`/reports/AllBarries`, { state: { ...item, ...dropdownData } });
  };

  const getResolvedPercentage = (categories) => {
    return (parseInt(categories?.resolved_barrier_count) / (parseInt(categories?.resolved_barrier_count) + parseInt(categories?.others_barrier_count))) * 100
  }

  const getOthersPercentage = (categories) => {
    return (parseInt(categories?.others_barrier_count) / (parseInt(categories?.resolved_barrier_count) + parseInt(categories?.others_barrier_count))) * 100
  }

  return (
    <div>
      <Grid item container lg={12} spacing={2}>
        {(patientJourneyData || []).map((patientItem, i) => {
          return (
            <Grid lg={4} sm={6} xs={12} item >
              <div className="grid-box" style={{ height: "360px", border: "1px solid #D3DDEA" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: '12px' }}>
                  <div style={{ display: "flex", alignItems: "center", marginLeft: "0 20px", gap: "11.31px", }}>
                    {patientJourneyImages[i]}
                    <div style={{ fontFamily: "Poppins-Medium", fontSize: 16, color: "#717579", textTransform: 'capitalize' }} >
                      {patientItem?.journey_stage_name}
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", justifyContent: 'center' }}>

                    <div style={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
                      <div onClick={() => { navigateAllDetails(patientItem) }} className='navigate-details' >
                        View Details
                      </div>
                    </div>
                  </div>
                </div>


                <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }} >
                  <div style={{ display: "flex", flexDirection: "column" }} >
                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: 'row', gap: 2, alignItems: 'center' }} >
                      <InputLabel style={{ fontSize: 35, fontFamily: "Poppins-Medium", color: "#464A53", lineHeight: "1em" }}>
                        {parseInt(patientItem.standard_days || 0)}
                      </InputLabel >

                      {/* {parseInt(patientItem?.standard_days || 0) - parseInt(patientItem?.actual_days || 0) < 0 ? <BsCaretUpFill
                        style={{ height: 19, width: 22, cursor: "pointer", color: "#EE2D2D", }}
                      /> :
                        <BsCaretDownFill style={{ height: 19, width: 22, cursor: "pointer", color: "#62E89C", }} />}
                      <InputLabel
                        style={{ display: 'flex', alignItems: 'center', fontSize: 18, fontFamily: "Poppins-Medium", color: parseInt(patientItem?.standard_days) - parseInt(patientItem?.actual_days) < 0 ? "#EE2D2D" : "#62E89C", }}>
                        {Math.abs(parseInt(patientItem.standard_days || 0) - parseInt(patientItem.actual_days || 0)) || 0}
                      </InputLabel> */}
                    </div>

                    <InputLabel style={{ fontFamily: "Poppins-Regular", fontSize: 12, color: "#656565", }} >
                      Estimated Days
                    </InputLabel>
                  </div>

                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "20px 0px",
                      width: "60%",

                    }}
                  >
                    <Box sx={{ flex: 1, flexDirection: "column" }}>
                      <InputLabel
                        style={{
                          fontSize: 12,
                          fontFamily: "Poppins-Regular",
                          color: "#656565",
                        }}
                      >
                        {"No of days"}
                      </InputLabel>
                      <ProgressWithDynamicTooltip data={{
                        estimatedDays: parseInt(patientItem?.standard_days),
                        actual: parseInt(patientItem?.actual_days)
                      }}
                        height={14} />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <InputLabel
                          style={{
                            fontSize: 12,
                            fontFamily: "Poppins-Regular",
                            color: "#656565",
                            width: '50%',
                            whiteSpace: 'inherit',
                            overflow: 'hidden',
                          }}
                        >
                          {patientItem?.start_process}
                        </InputLabel>
                        <InputLabel
                          style={{
                            fontSize: 12,
                            fontFamily: "Poppins-Regular",
                            color: "#656565",
                            width: '50%',
                            whiteSpace: 'inherit',
                            overflow: 'hidden',
                            textAlign: 'right',
                          }}
                        >
                          {patientItem?.end_process}
                        </InputLabel>
                      </Box>
                    </Box>
                  </Box>


                </div>

                <div className="hr-row">
                </div>
                <Grid container style={{ height: 157, overflow: 'hidden', overflowY: 'auto', paddingRight : 10 }}>
                  <Grid container style={{ paddingBottom: '8px', paddingTop: "20px" }}>
                    <Grid lg={4} sm={4} xs={4} item >
                      <div className='text-head'>
                        Barrier
                      </div>
                    </Grid>
                    {/* <Grid lg={3} sm={3} xs={3} item >
                      <div className='text-head'>

                      </div>
                    </Grid> */}
                    <Grid lg={6} sm={6} xs={6} item >
                      <div className='text-head'>
                        Resolved/Others
                      </div>
                    </Grid>
                    <Grid lg={2} sm={2} xs={2} item >
                      <div className='text-head'  >
                        Expense(USD)
                      </div>
                    </Grid>
                  </Grid> 
                  

                  {patientItem?.barrier_category?.length ? (patientItem?.barrier_category || []).map((barrierItem, j) => (
                    <Grid container style={{ marginBottom: '16px' }} key={j} spacing={2}>
                      <Grid lg={4} sm={4} xs={4} item>
                        <div className="text-detail" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {barrierItem?.category_name}
                        </div>
                      </Grid>
                      {console.log(getOthersPercentage(barrierItem),"getOthersPercentage(barrierItem)")}
                      <Grid lg={6} sm={6} xs={6} item>
                        <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                          <div style={{width: '100%'}}>
                            <ProgressBar variant="progress1" now={getResolvedPercentage(barrierItem)} className='progressbar'  />
                            <ProgressBar variant="progress2" now={getOthersPercentage(barrierItem)} className='progressbar' />
                          </div>
                          <div className="text-detail" style={{width: '100%'}}>
                            {barrierItem?.resolved_barrier_count + "/" + barrierItem?.others_barrier_count}
                          </div>
                        </div>
                      </Grid>
                      {/* <Grid lg={3} sm={3} xs={3} item>
                        
                      </Grid> */}
                      <Grid lg={2} sm={2} xs={2} item style={{paddingLeft: 5}}>
                        <div className="text-detail">
                          {barrierItem?.todo_expense || 0}
                        </div>
                      </Grid>
                    </Grid>
                  )) : <div style={{textAlign: 'center', fontSize: 12, color: '#BFBFBF', width: '100%'}}>No Data Found</div>}
                </Grid>

                <Grid container>
                  <Grid item lg={12}>
                    <p onClick={() => { navigateAllBarriers(patientItem) }} className='navigate-all'>
                      View All Barriers
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )
        })}





      </Grid>
    </div>

  )
}


export default PatientJourneyBox;